import analysisReportApi from 'api/analysis-report-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import FilterParameters from 'model/filter-parameters';

const analysisReportService = () => {
  const generateReport = async (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    try {
      const result: AxiosResponse<Blob> = await analysisReportApi.generateReport(filterParameters);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const generateReportCcb = async (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    try {
      const result: AxiosResponse<Blob> = await analysisReportApi.generateReportCcb(filterParameters);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    generateReport,
    generateReportCcb,
  };
};

export default analysisReportService();
