import { verifyIfIos } from 'components/styled-input/styles';
import styled from 'styled-components';

interface Props {
  isOptionSelected?: boolean;
  disabled?: boolean;
  error?: boolean;
  activated?: boolean;
}

export const Container = styled.div`
  position: relative;
  min-width: 286px;
  width: 100%;

  @media only screen and (max-width: 675px) {
    min-width: 256px;
    width: 256px;
  }
`;

export const SelectContent = styled.button<Props>`
  width: 100%;
  height: 44px;
  background-color: transparent;
  border: ${props => (props.error ? '2px solid #FC4F4F' : props.activated ? props.theme.color.primaryColor : '1px solid #999999')};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: ${({ isOptionSelected }) => (isOptionSelected && verifyIfIos ? '#333333' : '#b3b2b3')};
  }

  input {
    background-color: transparent;
    border: none;
    height: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${props => (props.disabled && !verifyIfIos ? '#999999' : '#333333')};
    border: none;
    width: 100%;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #999999;
    }
    :-ms-input-placeholder {
      color: #999999;
    }
  }
`;

export const OptionContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  max-height: 160px;

  overflow: auto;

  width: 100%;
  z-index: 10;

  position: absolute;

  button:last-child {
    border: none;
  }

  button {
    width: 100%;
    text-align: left;
    border-bottom: 0.5px solid #cccccc;
  }
`;

export const Option = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  span {
    display: inline-block;
    padding: 15px;
  }

  :hover {
    background-color: #f6f6f6;
  }
`;
