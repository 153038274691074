import EnterCustomButton from 'components/enter-custom-button';
import { Status } from 'features/admin/dashboard/components/status';
import { AuthBiometric } from 'model/auth-biometric';
import { Client } from 'model/client';
import { Authority } from 'model/enums/authority';
import FinancingStatusType from 'model/enums/financing-status-type';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { RegistrationStatus } from 'model/enums/registration-status';
import SystemStepCategory from 'model/enums/system-step-category';
import TypeVerifyIdentity from 'model/enums/type-verify-identity';
import { BankAccountInFinancing, Financing } from 'model/financing';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import financingService from 'services/financing-service';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import {
  BackGround,
  BackSection,
  BasicAnalysisInformation,
  ButtonsContainer,
  ButtonSection,
  Container,
  Identification,
  IdentificationSection,
  PageName,
  StatusContainer,
} from './styles';

interface FinancingCode {
  id?: string;
  ccb?: string;
}

type FinancingCodeOrNull = FinancingCode | null;
type FinanceStatusOrNull = FinancingStatusType | null;
type BankAccountInFinancingOrNull = BankAccountInFinancing | null;
type AuthBiometricOrNull = AuthBiometric | null;
type stepFinanceOrNull = Financing | null;

interface Props {
  financingCodes?: FinancingCodeOrNull;
  financeStatus?: FinanceStatusOrNull;
  systemSteps: SystemStepCategory[];
  verifyIdentity?: TypeVerifyIdentity;
  authBiometry?: AuthBiometricOrNull;
  isShowApproveCreditButton?: boolean;
  stepFinance?: stepFinanceOrNull;
  bankAccountInfos?: BankAccountInFinancingOrNull;
  showAnalisysInfoModal: boolean;
  setIsShowModalCreateBankAccount: (value: boolean) => void;
  setShowCancelModal: (value: boolean) => void;
  setIsShowSimulatorModal?: (value: boolean) => void;
  setIsShowAnalyzeFinancingModal: (value: boolean) => void;
  clientData?: Client;
  setAuthBiometry: (value: AuthBiometricOrNull) => void;
  setShowAnalisysInfoModal: (value: boolean) => void;
}

const TitleHeader: React.FC<Props> = ({
  setShowAnalisysInfoModal,
  setAuthBiometry,
  setIsShowAnalyzeFinancingModal,
  setShowCancelModal,
  setIsShowModalCreateBankAccount,
  setIsShowSimulatorModal,
  bankAccountInfos,
  stepFinance,
  isShowApproveCreditButton,
  authBiometry,
  verifyIdentity,
  systemSteps,
  financingCodes,
  financeStatus,
  clientData,
  showAnalisysInfoModal,
}) => {
  const { color } = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<{ prevPath: string }>();
  const [pageName, setPageName] = useState('');
  const [pageIdentification, setPageIdentification] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isIdentityVerificationButtonLoading, setIsIdentityVerificationButtonLoading] = useState<boolean>(false);

  const { financingId } = useParams<{ financingId: string }>();
  const account = useSelector((state: IRootState) => state.authentication.account);

  useEffect(() => {
    handlePageName();
  }, [financingCodes]);

  const handlePageName = () => {
    financingCodes?.id ? setPageIdentification(financingCodes?.id) : setPageIdentification(financingCodes?.ccb ? financingCodes?.ccb : '');
    financingCodes?.ccb ? setPageName(t('admin.customerAnalysis.ccb')) : setPageName(t('admin.customerAnalysis.pageTitle'));
  };

  useEffect(() => {
    if (hasError) {
      setTimeout(() => {
        setLoading(false);
        setHasError(false);
      }, 200);
    }
  }, [hasError]);

  const getAuthBiometry = async () => {
    if (financingId != null) {
      const res = await financingService.getAuthenticateAndBiometry(Number(financingId));
      setAuthBiometry(res);
      setIsIdentityVerificationButtonLoading(false);
    }
  };

  const biometricsRequest = () => {
    setIsIdentityVerificationButtonLoading(true);
    financingService.biometricsRequest(Number(financingId)).then(() => getAuthBiometry());
  };

  const twoFactorAuthRequest = () => {
    setIsIdentityVerificationButtonLoading(true);
    financingService.twoFactorAuthRequest(Number(financingId)).then(() => getAuthBiometry());
  };

  const handleDisplayCreditApprovalModals = (isFinancingConfirmed?: boolean) => {
    if (!isFinancingConfirmed) {
      setIsShowSimulatorModal ? setIsShowSimulatorModal(true) : '';
    } else if (bankAccountInfos?.bankAccount == null && bankAccountInfos?.financingPaymentType === FinancingPaymentType.CLIENT) {
      setIsShowModalCreateBankAccount(true);
    }
  };

  const buttonConfig = [
    {
      condition: () =>
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA) &&
        financeStatus === FinancingStatusType.IN_PROGRESS &&
        verifyIdentity === TypeVerifyIdentity.NECESSARY &&
        authBiometry?.twoFactorAuth?.[0] == null,
      text: t('admin.customerAnalysis.startAuthentication'),
      onClick: twoFactorAuthRequest,
    },
    {
      condition: () =>
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
        financeStatus === FinancingStatusType.IN_PROGRESS &&
        verifyIdentity === TypeVerifyIdentity.NECESSARY &&
        authBiometry?.biometrics?.status == null &&
        hasAuthorities(account?.authorities, [
          Authority.ROLE_ADMIN,
          Authority.ROLE_INTERNAL_ADMIN,
          Authority.ROLE_MANAGER,
          Authority.ROLE_FINANCIAL,
          Authority.ROLE_ANALYST,
        ]) &&
        (authBiometry?.twoFactorAuth?.[0]?.verified || !systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA)),
      text: t('admin.customerAnalysis.startBiometrics'),
      onClick: biometricsRequest,
    },
    {
      condition: () =>
        isShowApproveCreditButton &&
        hasAuthorities(account?.authorities, [
          Authority.ROLE_ADMIN,
          Authority.ROLE_INTERNAL_ADMIN,
          Authority.ROLE_MANAGER,
          Authority.ROLE_FINANCIAL,
        ]),
      text: t('admin.customerAnalysis.approveCredit'),
      onClick: () => handleDisplayCreditApprovalModals(stepFinance?.isConfirmed),
    },
    {
      condition: () =>
        bankAccountInfos?.bankAccount == null &&
        bankAccountInfos?.financingPaymentType === FinancingPaymentType.CLIENT &&
        stepFinance?.isConfirmed &&
        hasAuthorities(account?.authorities, [
          Authority.ROLE_ADMIN,
          Authority.ROLE_INTERNAL_ADMIN,
          Authority.ROLE_MANAGER,
          Authority.ROLE_FINANCIAL,
          Authority.ROLE_ANALYST,
        ]),
      text: t('admin.customerAnalysis.addBankDetails'),
      onClick: () => setIsShowModalCreateBankAccount(true),
    },
    {
      condition: () =>
        financeStatus != null &&
        (financeStatus === FinancingStatusType.VALIDATION || financeStatus === FinancingStatusType.ADJUSTED) &&
        hasAuthorities(account?.authorities, [
          Authority.ROLE_ADMIN,
          Authority.ROLE_MANAGER,
          Authority.ROLE_FINANCIAL,
          Authority.ROLE_INTERNAL_ADMIN,
        ]) &&
        clientData?.registrationStatus === RegistrationStatus.APPROVED,
      text: t('admin.customerAnalysis.buttonText'),
      onClick: () => setIsShowAnalyzeFinancingModal(true),
    },
  ];

  const getButtonProps = () => {
    const config = buttonConfig.find(item => item.condition());
    return config || { text: '', onClick: () => {} };
  };

  const { text, onClick } = getButtonProps();

  return (
    <BackGround>
      <Container>
        <BasicAnalysisInformation>
          <BackSection
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.push('/admin/creditos');
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
            <span>Voltar</span>
          </BackSection>
          <IdentificationSection>
            <PageName>
              {pageName} {`${' /'}`} <Identification>{`#${pageIdentification}`}</Identification>
            </PageName>
            <StatusContainer>
              <Status text={t(`enum.filterAllStatus.${financeStatus}`)} color={financeStatus ?? ''} />
            </StatusContainer>
          </IdentificationSection>
        </BasicAnalysisInformation>
        <ButtonSection>
          <ButtonsContainer AddBankAccountButton={stepFinance?.bankAccount == null}>
            <EnterCustomButton
              onClick={() => setShowAnalisysInfoModal(!showAnalisysInfoModal)}
              isInvertColor
              isLoading={false}
              text="Histórico de ações"
            />
            {text.length > 0 && <EnterCustomButton onClick={() => onClick()} isLoading={false} text={text} />}
          </ButtonsContainer>
        </ButtonSection>
      </Container>
    </BackGround>
  );
};

export default TitleHeader;
