import { yupResolver } from '@hookform/resolvers/yup';
import CustomButton from 'components/custom-button';
import InputText from 'components/styled-input';
import { RoutePath } from 'model/enums/route-path';
import { User } from 'model/user';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import authenticationService from 'services/authentication-service';
import toastUtils from 'shared/util/toast-utils';
import * as yup from 'yup';
import LogoutIcon from '../../../../../images/side-panel-close.svg';
import PasswordRequerimentsProps from '../../components/password-requeriments/index';
import {
  AccountDataContainer,
  ButtonAccountData,
  ButtonResetPassword,
  InputContainer,
  LogOutAccountContainer,
  LogOutButton,
  PassworRequerimestsContainer,
  ResetPasswordContainer,
  ResetPasswordInputContainer,
  ThinBackground,
  TitleContainer,
} from './styles';

interface InputProps {
  inputGap?: number;
}

interface MyDataModalProps {
  text?: string;
  icon?: ReactElement;
  setCloseOnDocumentClick: (value: boolean) => void;
  closeMenu: () => void;
}
interface AccountDataSchema {
  name: string;
  email: string;
}

interface ResetPasswordSchema {
  password?: string;
  confirmPassword?: string;
  name: string;
  email: string;
}

const ProfileAdmin: React.FC<InputProps> = ({ inputGap, ...props }) => {
  const [editMode, setEditMode] = useState(false);
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [password, setPassword] = useState('');
  const history = useHistory();
  const [passwordValid, setPasswordValid] = useState(true);

  const upperCaseRegex = /^(?=.*[A-Z])/;
  const lowerCaseRegex = /^(?=.*[a-z])/;
  const atLeastOneNumberRegex = /^(?=.*[0-9])/;
  const specialCharacterRegex = /^(?=.*[!@#\$%\^&\*])/;
  const characterSequenceRegex = /^(?!.*(.)\1{2})(?!.*(?:012|123|234|345|456|567|678|789|890))(?!.*(?:Giro|giro)).+$/;

  const { t } = useTranslation();
  useEffect(() => {
    if (editMode) {
      resetPasswordMethods.setValue('password', '');
      resetPasswordMethods.setValue('confirmPassword', '');
    }
  }, [editMode]);

  const accountDataSchema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
  });

  const resetPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .required(t('global.errorMessage.required'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, t('global.errorMessage.requirementsPassword')),
    confirmPassword: yup
      .string()
      .required(t('global.errorMessage.required'))
      .oneOf([yup.ref('password'), null], t('global.errorMessage.matchPasswords')),
  });

  const accountDataMethods = useForm<AccountDataSchema>({ resolver: yupResolver(accountDataSchema) });
  const resetPasswordMethods = useForm<ResetPasswordSchema>({ resolver: yupResolver(resetPasswordSchema) });

  const updateProfile = async (values: AccountDataSchema) => {
    if (account?.id) {
      const data: User = {
        firstName: values.name,
        login: values.email,
      };

      await authenticationService.updateAdminProfile(data);

      toastUtils.emitSuccessToast(t('global.successMessage.changeMade'));
    }
  };

  const updatePassword = async (values: ResetPasswordSchema) => {
    if (account?.id) {
      const data: User = {
        firstName: account?.firstName,
        login: account?.email,
        password: values.password,
      };

      await authenticationService.updateAdminProfile(data);

      toastUtils.emitSuccessToast(t('global.successMessage.passwordChanged'));
    }
  };

  const passwordIsValid = () => {
    const valid =
      password.length >= 8 &&
      upperCaseRegex.test(password) &&
      lowerCaseRegex.test(password) &&
      specialCharacterRegex.test(password) &&
      atLeastOneNumberRegex.test(password) &&
      characterSequenceRegex.test(password);
    setPasswordValid(!valid);
  };

  useEffect(() => {
    passwordIsValid();
  }, [password]);

  return (
    <ThinBackground>
      <FormProvider {...accountDataMethods}>
        <LogOutAccountContainer>
          <AccountDataContainer>
            <InputContainer inputGap={24}>
              <TitleContainer>{t('admin.modalProfile.accountData')}</TitleContainer>
              <InputText name="email" label={t('admin.modalProfile.email')} defaultValue={account?.email} disabled={!editMode} />
              <InputText name="name" label={t('admin.modalProfile.name')} defaultValue={`${account?.firstName ?? ''}`} />
              <ButtonAccountData>
                <CustomButton
                  isInvertColor
                  style={{ justifyContent: 'center' }}
                  height={48}
                  width="160px"
                  onClick={accountDataMethods.handleSubmit(updateProfile)}
                  freeContent
                >
                  {t('global.button.save')}
                </CustomButton>
              </ButtonAccountData>
            </InputContainer>
          </AccountDataContainer>

          <LogOutButton>
            <CustomButton
              style={{ justifyContent: 'center' }}
              height={48}
              width="160px"
              onClick={() => history.push(RoutePath.LOGOUT)}
              iconSrc={LogoutIcon}
            >
              {t('global.button.logout')}
            </CustomButton>
          </LogOutButton>
        </LogOutAccountContainer>
      </FormProvider>

      <FormProvider {...resetPasswordMethods}>
        <ResetPasswordContainer>
          <TitleContainer>{t('admin.modalProfile.resetPassword')}</TitleContainer>
          <ResetPasswordInputContainer>
            <InputContainer>
              <InputText
                onChange={e => setPassword(e.target.value)}
                name="password"
                label={t('admin.modalProfile.newPassword')}
                placeholder={t('login.placeholder.newPassword')}
                inputType="password"
                notIcon={editMode}
              />
            </InputContainer>
            <InputContainer>
              <InputText
                name="confirmPassword"
                label={t('admin.modalProfile.confirmPassword')}
                placeholder={t('login.placeholder.newPassword')}
                inputType="password"
                notIcon={editMode}
              />
            </InputContainer>
          </ResetPasswordInputContainer>

          <PassworRequerimestsContainer>
            <PasswordRequerimentsProps password={password} />
          </PassworRequerimestsContainer>

          <ButtonResetPassword>
            <CustomButton isInvertColor style={{ justifyContent: 'center' }} height={48} onClick={() => history.goBack()}>
              {t('global.button.cancel')}
            </CustomButton>

            <CustomButton
              disabled={passwordValid}
              style={{ justifyContent: 'center' }}
              height={48}
              onClick={resetPasswordMethods.handleSubmit(updatePassword)}
            >
              {t('global.button.confirm')}
            </CustomButton>
          </ButtonResetPassword>
        </ResetPasswordContainer>
      </FormProvider>
    </ThinBackground>
  );
};

export default ProfileAdmin;
