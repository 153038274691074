export enum Screens {
  DASHBOARD = 'dashboard',
  REGISTERS = 'cadastros',
  CREDIT_ANALYSIS = 'creditos',
  CONTRACTS = 'contratos',
  PAYMENTS = 'pagamentos',
  MY_TEAM = 'minha-equipe',
  ANTECIPATION = 'antecipacoes',
  PROCEDURE = 'procedimentos',
  SIGNATURES = 'assinaturas',
  REPORT = 'relatorio',
  BRANCH = 'filial',
  MY_ACCOUNT = 'minha-conta',
  REPORT_CCB = 'relatorio-ccb',
  PROFILE_ADMIN = 'perfil',
}
