import styled from 'styled-components/macro';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 104px;
  background: #fff;
`;

export const Content = styled.div`
  width: 1004px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
`;

export const Title = styled.span`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--Cool-gray-100, #121619);
`;

export const TitleContainer = styled.div`
  width: 624px;
  height: 38px;
  padding-top: 41px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100px;
  height: 48px;
  padding-top: 32px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  padding-top: 32px;
  cursor: pointer;

  button {
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
`;
