import styled from 'styled-components/macro';

interface IButtonProps {
  width?: number;
  fullSize?: boolean;
  textWidth?: number;
  maxWidth?: number;
  isMobile?: boolean;
  isInvertColor?: boolean;
}

export const Button = styled.button<IButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: ${props => (props.fullSize ? '100%' : props.width ? `${props.width}px` : props.isMobile ? 'none' : '190px')};
  min-width: 190px;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
  height: 48px;
  background: ${({ theme, isInvertColor }) => (isInvertColor ? `white` : theme.color.primaryColor)};
  border-radius: 8px;
  border: 1.5px solid ${({ theme }) => theme.color.primaryColor};

  :disabled {
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: ${props => (props.textWidth ? `${props.textWidth}px` : '16px')};
    line-height: normal;
    text-align: center;
    color: ${({ theme, isInvertColor }) => (isInvertColor ? theme.color.primaryColor : `white`)};
  }
`;
export const Spin = styled.div`
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  animation: spin 2s infinite linear;
  border: 1px solid #e5e7e7;
  border-top: 2px solid #fff;

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
`;
