import { useTheme } from 'styled-components';

const WelcomeImage = () => {
  const { color } = useTheme();

  return (
    <svg width="316" height="106" viewBox="0 0 316 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        opacity="0.5"
        cx="158"
        cy="158"
        r="126"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 316 0)"
        stroke="url(#paint0_linear_788_4842)"
        strokeWidth="64"
      />
      <circle
        opacity="0.3"
        cx="63.2"
        cy="63.2"
        r="63.2"
        transform="matrix(-2.18557e-07 1 1 2.18557e-07 94.8002 94.4839)"
        fill="url(#paint1_linear_788_4842)"
      />
      <defs>
        <linearGradient id="paint0_linear_788_4842" x1="0" y1="316" x2="447.85" y2="316" gradientUnits="userSpaceOnUse">
          <stop stopColor={color.primaryColor} />
          <stop offset="1" stopColor={color.colorWelcomeImage} />
        </linearGradient>
        <linearGradient id="paint1_linear_788_4842" x1="-47.3342" y1="126.584" x2="126.4" y2="126.4" gradientUnits="userSpaceOnUse">
          <stop stopColor="#666666" />
          <stop offset="1" stopColor={color.colorWelcomeImage} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WelcomeImage;
