import { ReactComponent as PriorizationIconSvg } from 'images/prioritization.svg';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import { EmptyList } from '../empty-list';
import { Column, Container, Header, LoadingContainer, PaginationContainer, Row, Separator, Table, TableContainer } from './styles';

export interface TableId {
  first: number;
  second?: number;
}
export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  ids?: TableId[];
  onClick?: (ids?: TableId) => void;
  fullLength?: boolean;
  maxWidth?: string;
  paddingLeft?: string;
  styleRow?: CSSProperties;
  centerLine?: boolean;
  sortColumn?: string;
  showMore?: string;
}

interface TableListProps {
  tableValues: TableValues[];
  page?: number;
  totalPages?: number;
  setNewPage?: (page: number) => void;
  editMode?: boolean;
  noPagination?: boolean;
  internal?: boolean;
  setSortColumn?: (value: string) => void;
  setOrderBy?: (value: boolean) => void;
  orderBy?: boolean;
  handlePagination?: () => void;
  containsNextPage?: boolean;
  loading?: boolean;
  setViewMore?: (value: boolean) => void;
  viewMore?: boolean;
  displayOrdering?: boolean;
}

export const TableList = ({
  setViewMore,
  viewMore,
  loading,
  setOrderBy,
  orderBy,
  setSortColumn,
  tableValues,
  totalPages,
  page,
  setNewPage,
  noPagination,
  editMode,
  internal,
  handlePagination,
  containsNextPage,
  displayOrdering = true,
}: TableListProps) => {
  const [currentPage, setCurrentPage] = useState('1');
  const [isEmpty, setIsEmpty] = useState(false);
  const { t } = useTranslation();
  const { color } = useTheme();
  const [seeMoreIsLoading, setSeeMoreIsLoading] = useState(false);
  const [tableSize, setTableSize] = useState<number>(0);

  useEffect(() => {
    const values = tableValues.map(table => table.values).flat();
    setIsEmpty(values.length <= 0);
    setTableSize(values.length);
  }, [tableValues]);

  useEffect(() => {
    if (page != null) {
      setCurrentPage((page + 1).toString());
    }
  }, [page]);

  const handleSeeMoreClick = () => {
    if (tableSize === tableValues.map(table => table.values).flat().length) {
      setSeeMoreIsLoading(true);
      setViewMore ? setViewMore(true) : '';
      handlePagination ? handlePagination() : '';
    }
  };

  useEffect(() => {
    if (seeMoreIsLoading) {
      const values = tableValues.map(table => table.values).flat();
      if (values.length > tableSize) {
        setSeeMoreIsLoading(false);
      }
    }
  }, [tableValues]);

  if (loading) {
    return (
      <LoadingContainer>
        <ReactLoading type="spinningBubbles" color={color.primaryColor} width="64px" height="64px" />
      </LoadingContainer>
    );
  }

  if (tableValues.length === 0) {
    return <EmptyList />;
  }

  return (
    <Container>
      <TableContainer>
        <Table internal={internal}>
          {tableValues.map((table, tableIndex) => (
            <Column key={table.header} maxWidth={table?.maxWidth}>
              <Header paddingLeft={table?.paddingLeft}>
                {table.header}
                {displayOrdering && (
                  <PriorizationIconSvg
                    onClick={() => {
                      setSortColumn ? setSortColumn(table.sortColumn ? table.sortColumn : '') : '';
                      setOrderBy ? setOrderBy(orderBy ? false : true) : '';
                    }}
                  />
                )}
              </Header>
              <Separator />
              {table.values.map((row, rowIndex) => (
                <React.Fragment key={`${tableIndex}-${rowIndex}`}>
                  <Row
                    onClick={() => {
                      if (table.onClick && table.ids) {
                        table.onClick(table.ids[rowIndex]);
                      }
                    }}
                    blank={row === ''}
                    fullLength={table.fullLength}
                    style={table?.styleRow}
                    paddingLeft={table?.paddingLeft}
                    centerLine={row === '---'}
                  >
                    <span>{row === '' ? '.' : row}</span>
                  </Row>
                  {rowIndex !== table.values.length - 1 && <Separator />}
                </React.Fragment>
              ))}
            </Column>
          ))}
        </Table>
      </TableContainer>

      {containsNextPage && (
        <PaginationContainer onClick={handleSeeMoreClick}>
          {seeMoreIsLoading ? (
            <ReactLoading type="spinningBubbles" color={color.primaryColor} width="24px" height="24px" />
          ) : (
            <span>{t('tableList.viewMore')}</span>
          )}
        </PaginationContainer>
      )}
    </Container>
  );
};
