import styled from 'styled-components/macro';

export const ContainerFilterButton = styled.div`
  width: 1004px;
  height: 64px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  margin-top: 64px;
  display: flex;
`;

export const FilterButton = styled.button`
  background-color: ${({ theme }) => theme.color.primaryColor};
  height: 32px;
  padding: 6px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: none;

  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.white};
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 24px;
  gap: 8px;
`;

export const ActiveFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 32px;
`;

export const ActiveFilter = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 9999px;
  border: 1px solid var(--Other-i, #8089ab);
  padding: 0px 8px;
  cursor: pointer;
`;

export const CloseIconContainer = styled.div`
  width: 16px;
  height: 16px;
  background-color: #8089ab;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClearFiltersContainer = styled.div`
  width: 46px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
