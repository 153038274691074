import CustomButton from 'components/custom-button';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterNameButtonContainer,
  FilterNameContainer,
  InputContainer,
  Labelcontainer,
  ModalFilterName,
  SeparatorInput,
  SerachResponseContainer,
  SerachResponseContent,
} from './styles';

interface TableListFiltersProps {
  showStatusFilter: boolean;
  onCloseModal: () => void;
  setFinancingStatus?: (value: string) => void;
  statusOptions?: StatusTypeFilter[];
}

interface StatusTypeFilter {
  label: string;
  value: string;
}

const TableListFilterStatus: React.FC<TableListFiltersProps> = ({ statusOptions, setFinancingStatus, showStatusFilter, onCloseModal }) => {
  const [currentStatus, setCurrentStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const filteredOptions = statusOptions
    ? statusOptions.filter(option => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentStatus(event.target.value);
    setIsDropdownVisible(true);
  };

  const handleOptionClick = (option: StatusTypeFilter) => {
    setCurrentStatus(option.value);
    setSearchTerm(option.label);
    setIsDropdownVisible(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleMarginTop = () => {
    return filteredOptions.length > 4 ? 200 : filteredOptions.length * 48;
  };

  return (
    <FilterNameContainer ref={containerRef}>
      <ModalFilterName expanded={showStatusFilter}>
        <Labelcontainer>
          <span>{'Status'}</span>
        </Labelcontainer>
        <InputContainer>
          <input
            value={searchTerm}
            onClick={() => setIsDropdownVisible(true)}
            onChange={handleInputChange}
            placeholder={'Procure pelo status'}
          />
        </InputContainer>
        {isDropdownVisible && (
          <SerachResponseContainer marginTop={handleMarginTop()}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <React.Fragment key={option.value}>
                  <SerachResponseContent onClick={() => handleOptionClick(option)}>
                    <span>{option.label}</span>
                  </SerachResponseContent>
                  <SeparatorInput />
                </React.Fragment>
              ))
            ) : (
              <></>
            )}
          </SerachResponseContainer>
        )}
        <FilterNameButtonContainer>
          <CustomButton onClick={() => onCloseModal()} isInvertColor height={48} width="200px">
            Cancelar
          </CustomButton>
          <CustomButton
            height={48}
            width="200px"
            onClick={() => {
              setFinancingStatus ? setFinancingStatus(currentStatus) : '';
              onCloseModal();
            }}
          >
            Aplicar
          </CustomButton>
        </FilterNameButtonContainer>
      </ModalFilterName>
    </FilterNameContainer>
  );
};

export default TableListFilterStatus;
