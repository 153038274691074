import React from 'react';
import { useTheme } from 'styled-components';

interface Props {
  isWarning?: boolean;
  changeColor?: string;
}

const ProgressBarIcon: React.FC<Props> = ({ isWarning, changeColor }) => {
  const { color } = useTheme();

  return (
    <>
      {isWarning ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <rect width="20" height="20" fill="white" fillOpacity="0.01" />
          <path d="M10.625 5H9.375V11.875H10.625V5Z" fill="white" />
          <path
            d="M10 13.75C9.81458 13.75 9.63333 13.805 9.47915 13.908C9.32498 14.011 9.20482 14.1574 9.13386 14.3287C9.06291 14.5 9.04434 14.6885 9.08051 14.8704C9.11669 15.0523 9.20598 15.2193 9.33709 15.3504C9.4682 15.4815 9.63525 15.5708 9.8171 15.607C9.99896 15.6432 10.1875 15.6246 10.3588 15.5536C10.5301 15.4827 10.6765 15.3625 10.7795 15.2083C10.8825 15.0542 10.9375 14.8729 10.9375 14.6875C10.9375 14.4389 10.8387 14.2004 10.6629 14.0246C10.4871 13.8488 10.2486 13.75 10 13.75Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <rect width="16" height="16" fill="white" fillOpacity="0.01" />
          <path
            d="M6.5 12L2 7.49997L2.707 6.79297L6.5 10.5855L13.293 3.79297L14 4.49997L6.5 12Z"
            fill={changeColor === color.barColorForDefault ? '#A2A9B0' : 'white'}
          />
        </svg>
      )}
    </>
  );
};

export default ProgressBarIcon;
