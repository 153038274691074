import styled from 'styled-components/macro';

interface Props {
  inputGap?: number;
}

export const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: red;
`;

export const ThinBackground = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1148px;
  gap: 36px;
  margin: 0 auto;
  margin-top: 64px;
`;

export const AccountDataContainer = styled.div`
  width: 484px;
  height: 356px;
  border-radius: 16px;
  background: var(--White, #fff);

  span {
    font-weight: 600;
    color: var(--App-Cool-gray-100, #121619);
  }

  button {
    border: none;
    background: var(--White, #fff);
  }
`;

export const ResetPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 484px;
  height: 618px;
  border-radius: 16px;
  background: var(--White, #fff);

  span {
    font-weight: 600;
    color: var(--App-Cool-gray-100, #121619);
  }

  li {
    list-style-type: none;
    font-weight: 600;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 420px;
  height: 97px;
  gap: ${(props: Props) => `${props.inputGap}px` ?? '24px'};
`;

export const ResetPasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 226px;
  gap: 32px;
  margin-top: 24px;
`;

export const ButtonAccountData = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonResetPassword = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  button {
    width: 200px;
    border-radius: 8px;
  }
`;

export const TitleContainer = styled.span`
  width: 420px;
  height: 29px;
  flex-shrink: 0;
  margin-top: 24px;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--Cool-gray-100, #121619);
`;

export const PassworRequerimestsContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 40px;
`;

export const LogOutAccountContainer = styled.div`
  width: 484px;
  height: 336px;
`;

export const LogOutButton = styled.div`
  margin-top: 32px;

  button {
    width: 200px;
    border-radius: 8px;

    img {
      margin-right: 8px;
    }
  }
`;
