import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};
  max-width: 348px;
`;

export const QRCodeContainer = styled.div`
  width: 200px;
  height: 198px;
  margin-top: 64px;
`;

export const InfoContainer = styled.div`
  width: 220px;
  height: 168px;
  margin-top: 32px;
  margin-right: 318px;

  button {
    padding: 0px;
    width: 220px;
    height: 48px;
    border-radius: 8px;

    text-align: center;
    font-size: 16px;
    font-weight: 600;

    .linkCopied {
      margin: 0 auto;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 7px;
    margin-left: 7px;
  }
`;

export const MainContent = styled.div`
  display: flex;
  width: 100%;
  margin-left: 62px;
`;
