import styled from 'styled-components/macro';

export const MainContent = styled.div`
  margin-top: 48px;
  with: max-content;
  display: flex;
  justify-content: center;
`;

export const ContentFilter = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 16px;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 8px;

  width: 65%;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ContentCalendarInputsContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  gap: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
