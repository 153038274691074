import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

interface ThinProps {
  tittleFontSize?: string;
  subTitleFontSize?: string;
}

export const ThinBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:1440px;
  height: 100vh;
  margin: auto;
  padding: 40px 0;
  gap; 148px;
`;

export const RecoverySentBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:1440px;
  height: 100vh;
  margin: auto;
  padding: 40px 0;
  gap; 148px;
`;

export const NewPasswordContainer = styled.div`
  width: 420px;
`;

export const LeftContainer = styled.div`
  width: 420px;
  margin-bottom: 255px;
`;

export const LogoContainer = styled.div`
  margin-top: 56px;
`;

export const InputContainer = styled.div`
  margin-bottom: 150px;
`;

export const SideImageContainer = styled.div`
  gap: 148px;
  margin-left: 150px;

  img {
    border-radius: 16px;
  }
`;

export const ForgotPasseordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -30px;
`;

export const ImageContainer = styled.img``;

export const ThinHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 495px;

  span {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const ThinHeaderStroke = styled.img`
  width: 8px;
  margin-right: 15px;
  fill: #4b6fdd;
`;

export const ThinContainer = styled.div`
  margin: 56px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const ThinInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  gap: 32px;

  & > label + label {
    margin-top: 16px;
  }
`;

export const NewPasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
  gap: 8px;

  & > label + label {
    margin-top: 16px;
  }
`;

export const ThinButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ThinTitle = styled.span<ThinProps>`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  font-size: ${({ tittleFontSize }) => `${tittleFontSize}px` || '40px'};
  line-height: 43px;
  letter-spacing: 0.5px;
  color: #121619;
  text-align: start;
`;

export const ThinSubTitle = styled.span<ThinProps>`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ subTitleFontSize }) => `${subTitleFontSize}px` || '20px'};
  line-height: 150%;
  text-align: start;
  color: #697077;
`;
export const ThinTextWithLink = styled.span`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: start;
  color: #999999;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ThinRequirementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
`;
export const ThinRequirementTitle = styled.span`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7f7f7f;
`;

interface ThinRequirementItemProps {
  isValid?: boolean;
}
export const ThinRequirementItem = styled.li<ThinRequirementItemProps>`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${props => (props.isValid ? '#0DB052' : '#7f7f7f')};
`;

export const Link = styled(NavLink)`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primaryColor};
  text-decoration: none;
`;

export const LinkButton = styled.a`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primaryColor};
  text-decoration: none;
  cursor: pointer;
`;

export const QrcodeMainContainer = styled.div`
  width: 420px;
  height: auto;
  display: flex;

  flex-direction: column;
`;

export const AccessDeniedContainer = styled.div`
  max-width: 300px;
  height: 100vh;
  margin: auto;
  padding: 40px 0px;

  button {
    width: 100%;
  }
`;
