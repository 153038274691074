import styled from 'styled-components';

interface Props {
  isSelected?: boolean;
}

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;

  @media (max-width: 1495px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.div<Props>`
  > div.firstTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    > button {
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  & > div {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;

    span.title {
      display: inline-block;
      font-weight: 700;
      margin-left: 16px;
    }

    > span.code {
      margin-left: 8px;
    }

    > span.ccb {
      margin-left: 45px;
    }
  }
`;

export const TabContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGray};
  border-radius: 8px;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 32px;

  padding: 16px 24px 24px 24px;
`;

export const TabTitleHeader = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray24};
`;

export const TabHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const TabHeaderInnerContainer = styled.div`
  height: 25px;
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const TabClientName = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.color.statusNew};
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-decoration: underline;
  cursor: pointer;
`;

export const TabBox = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  padding: 4px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Tab = styled.button<Props>`
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.color.white : 'transparent')};
  border: ${({ theme, isSelected }) => (isSelected ? `1px solid ${theme.color.gray20}` : 'none')};
  box-shadow: ${({ theme, isSelected }) => (isSelected ? `0px 2px 12px ${theme.color.shadow}` : 'none')};
  border-radius: 6px;
  padding: 8px;

  flex: 1;

  > span {
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme, isSelected }) => (isSelected ? theme.color.primaryColor : theme.color.gray18)};
  }
`;

export const CardsContainer = styled.div`
  margin: 0 auto;
  max-width: 70%;
  margin-top: 21px;
  margin-bottom: 32px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 25px;
  row-gap: 21px;

  div {
    margin: 0;
  }
`;

export const CardText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};

  display: inline-block;
  margin-top: 8px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > span.credit {
    margin-left: 8px;
  }

  & > div.status {
    margin-top: 8px;
  }
`;

export const GuarantorCount = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.borderGray};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 8px !important;
  margin-left: 8px !important;

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray19};
  }
`;

export const IconBox = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.color.verylightBlue};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  & > svg {
    > path {
      fill: transparent;
    }
  }
`;

export const TabContent = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  & > button.cancel {
    background-color: ${({ theme }) => theme.color.rejected};
    border-color: ${({ theme }) => theme.color.rejected};
  }

  @media (max-width: 1495px) {
    width: 100%;
    display: flex;
    justify-content: right;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  width: 70%;
  margin: 0 auto;
  padding-left: 50px;

  column-gap: 10px;
`;
