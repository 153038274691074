import { CustomSideMenu } from 'components/custom-side-menu';
import { ORGANIZATION_NAME } from 'config/constants';
import Report from 'features/admin/dashboard/screens/report';
import { Screens } from 'model/enums/admin-dashboard';
import { OrganizationName } from 'model/enums/organization-name';
import StepType from 'model/enums/step-type';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import DashboardHeader from './components/dashboard-header';
import { BranchList } from './screens/branch';
import { ContractsList } from './screens/contracts';
import { CreditAnalysisList } from './screens/credit-analysis';
import { InitialDashboardScreen } from './screens/initial-screen';
import { MyAccountList } from './screens/my-account';
import { MyTeamList } from './screens/my-team';
import { PaymentsList } from './screens/payments';
import { ProcedureList } from './screens/procedure';
import ProfileAdmin from './screens/profile';
import { RegistersList } from './screens/registers';
import ReportCcb from './screens/report-ccb';
import { SignatureresList } from './screens/signatures';
import { Container, Content, HeaderContainer, PageContainer } from './styles';

export interface Tab {
  logo?: FunctionComponentElement<SVGSVGElement>;
  label?: string;
  url?: string;
}

export const AdminDashboard = () => {
  const history = useHistory();
  const [orgStepTypes, setOrgStepTypes] = useState<StepType[] | null>([]);
  const [titleHeader, setTitleHeader] = useState('');
  const [screen, setScreen] = useState(<></>);
  const [currentScreenPath, setCurrentScreenPath] = useState('');
  const [isHeaderClicked, setIsHeaderClicked] = useState(false);
  const [buttonTitle, setButtonTitle] = useState<string>('');

  useEffect(() => {
    getStepType();
  }, []);

  useEffect(() => {
    const splitedUrl = history.location.pathname.split('/');
    const screen = splitedUrl[splitedUrl.length - 1];
    if (screen === Screens.DASHBOARD && OrganizationName.NOVOSAQUE == ORGANIZATION_NAME) {
      return history.push('/admin/assinaturas');
    }
  }, []);

  useEffect(() => {
    setScreen(matchUrlPageContent());
  }, [orgStepTypes]);

  const getStepType = async () => {
    const res = await organizationSystemStepsService.getStepAdmin();
    const orgTypes = res
      .filter(
        orgType =>
          orgType.stepType === StepType.CREDIT_ANTICIPATION || orgType.stepType === StepType.CCB || orgType.stepType === StepType.PROCEDURE
      )
      .map(type => type.stepType);

    const filtered = orgTypes.filter((orgType, idx) => orgTypes.indexOf(orgType) === idx);

    if (filtered.length > 0) {
      setOrgStepTypes(filtered as StepType[]);
    }
  };

  const matchUrlPageContent = () => {
    const splitedUrl = history.location.pathname.split('/');
    const screen = splitedUrl[splitedUrl.length - 1];
    setCurrentScreenPath(screen);
    switch (screen) {
      case Screens.DASHBOARD:
        setButtonTitle('admin.dashboard.initialButtonText');
        setTitleHeader('admin.dashboard.initial');
        return <InitialDashboardScreen orgStepTypes={orgStepTypes} />;

      case Screens.REGISTERS:
        setTitleHeader('admin.dashboard.register');
        return <RegistersList />;

      case Screens.CREDIT_ANALYSIS:
        setTitleHeader('admin.dashboard.creditReviews');
        return <CreditAnalysisList />;

      case Screens.CONTRACTS:
        setTitleHeader('admin.dashboard.contracts');
        return <ContractsList />;

      case Screens.PAYMENTS:
        setTitleHeader('admin.dashboard.payments');
        return <PaymentsList />;

      case Screens.MY_TEAM:
        setButtonTitle('admin.dashboard.myTeamButtonText');
        setTitleHeader('admin.dashboard.myTeam');
        return <MyTeamList setIsHeaderClicked={setIsHeaderClicked} isHeaderClicked={isHeaderClicked} />;

      case Screens.PROCEDURE:
        setTitleHeader('admin.dashboard.procedure');
        return <ProcedureList />;

      case Screens.SIGNATURES:
        setTitleHeader('admin.dashboard.signatures');
        return <SignatureresList />;

      case Screens.ANTECIPATION:
        setTitleHeader('admin.dashboard.anticipations');
        return <></>;

      case Screens.REPORT:
        setTitleHeader('admin.dashboard.report');
        return <Report />;

      case Screens.BRANCH:
        setButtonTitle('admin.dashboard.branchButton');
        setTitleHeader('admin.dashboard.branch');
        return <BranchList />;

      case Screens.MY_ACCOUNT:
        setTitleHeader('admin.dashboard.myAccount');
        return <MyAccountList />;

      case Screens.REPORT_CCB:
        setTitleHeader('admin.dashboard.reportCcb');
        return <ReportCcb />;

      case Screens.PROFILE_ADMIN:
        setTitleHeader('admin.dashboard.profile');
        return <ProfileAdmin />;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    setScreen(matchUrlPageContent());
  }, [isHeaderClicked]);

  return (
    <PageContainer>
      <Container>
        <CustomSideMenu />
        <HeaderContainer>
          <DashboardHeader
            title={titleHeader}
            currentScreenPath={currentScreenPath}
            isHeaderClicked={isHeaderClicked}
            setIsHeaderClicked={setIsHeaderClicked}
            buttonDescription={buttonTitle}
          />
          <Content>{screen}</Content>
        </HeaderContainer>
      </Container>
    </PageContainer>
  );
};
