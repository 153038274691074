import { useTranslation } from 'react-i18next';
import { RequirementItem, RequirementsContainer, RequirementTitle } from './styles';

interface PasswordRequerimentsProps {
  password: string;
}

const PasswordRequeriments: React.FC<PasswordRequerimentsProps> = ({ password, ...props }) => {
  const upperCaseRegex = /^(?=.*[A-Z])/;
  const lowerCaseRegex = /^(?=.*[a-z])/;
  const atLeastOneNumberRegex = /^(?=.*[0-9])/;
  const specialCharacterRegex = /^(?=.*[!@#\$%\^&\*])/;
  const characterSequenceRegex = /^(?!.*(.)\1{2})(?!.*(?:012|123|234|345|456|567|678|789|890))(?!.*(?:Giro|giro)).+$/;

  const { t } = useTranslation();

  return (
    <>
      <RequirementsContainer>
        <RequirementTitle>{t('login.recovery.createPassword.requirements.title')}</RequirementTitle>
        <RequirementItem isValid={password.length >= 8 ? true : false}>
          {t('login.recovery.createPassword.requirements.stepOne')}
        </RequirementItem>
        <RequirementItem isValid={upperCaseRegex.test(password)}>{t('login.recovery.createPassword.requirements.stepTwo')}</RequirementItem>
        <RequirementItem isValid={lowerCaseRegex.test(password)}>
          {t('login.recovery.createPassword.requirements.stepThree')}
        </RequirementItem>
        <RequirementItem isValid={specialCharacterRegex.test(password)}>
          {t('login.recovery.createPassword.requirements.stepFour')}
        </RequirementItem>
        <RequirementItem isValid={atLeastOneNumberRegex.test(password)}>
          {t('login.recovery.createPassword.requirements.stepFive')}
        </RequirementItem>
        <RequirementItem isValid={characterSequenceRegex.test(password)}>
          {t('login.recovery.createPassword.requirements.stepSix')}
        </RequirementItem>
      </RequirementsContainer>
    </>
  );
};

export default PasswordRequeriments;
