import React from 'react';
import gregorian_pt_br from 'react-date-object/locales/gregorian_pt_br';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { CalendarContainer, StyledCalendar } from './styles';

export enum HeaderItem {
  LEFT_BUTTON = 'LEFT_BUTTON',
  MONTH_YEAR = 'MONTH_YEAR',
  RIGHT_BUTTON = 'RIGHT_BUTTON',
}

interface CustomCalendarProps {
  setDateValues: (value: DateObject[]) => void;
  dateValues: DateObject[];
}

const CustomCalendar: React.FC<CustomCalendarProps> = ({ setDateValues, dateValues }) => {
  const headerOrderlist: HeaderItem[] = [HeaderItem.MONTH_YEAR, HeaderItem.LEFT_BUTTON, HeaderItem.RIGHT_BUTTON];
  const weekDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  const dataAtual: Date = new Date();
  const dataNoFormatoISO: string = dataAtual.toISOString().split('T')[0];

  return (
    <CalendarContainer>
      <StyledCalendar>
        <Calendar
          locale={gregorian_pt_br}
          weekDays={weekDays}
          headerOrder={headerOrderlist}
          value={dateValues}
          onChange={setDateValues}
          range
          rangeHover
        />
      </StyledCalendar>
    </CalendarContainer>
  );
};

export default CustomCalendar;
