import React from 'react';
import { ButtonContainer, IconContainer, InfoStatusContainer, MainContainer, MainTitle, Separator, StatusContainer, Title } from './styles';

import CustomButton from 'components/custom-button';
import { ReactComponent as ChevronRight } from 'images/chevron-right-new.svg';
import { useHistory } from 'react-router-dom';

interface DashboardCardProps {
  redirectTo: string;
  mainTitle?: string;
  mainTotal?: number;
  firstStatus?: string;
  firstStatusTotal?: number;
  secondStatus?: string;
  secondStatusTotal?: number;
  thirdStatus?: string;
  thirdStatusTotal?: number;
  icon?: JSX.Element;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  mainTitle,
  mainTotal,
  firstStatus,
  firstStatusTotal,
  secondStatus,
  secondStatusTotal,
  thirdStatus,
  thirdStatusTotal,
  icon,
  redirectTo,
}) => {
  const history = useHistory();

  return (
    <MainContainer onClick={() => history.push(redirectTo)}>
      <MainTitle>
        <IconContainer>{icon}</IconContainer>

        <Title>
          <p>{mainTitle}</p>
          <span>{mainTotal}</span>
        </Title>
      </MainTitle>

      <Separator />

      <StatusContainer>
        <InfoStatusContainer>
          <p>{firstStatus}</p>
          <span>{firstStatusTotal}</span>
        </InfoStatusContainer>
        <InfoStatusContainer>
          <p>{secondStatus}</p>
          <span>{secondStatusTotal}</span>
        </InfoStatusContainer>
        <InfoStatusContainer>
          <p>{thirdStatus}</p>
          <span>{thirdStatusTotal}</span>
        </InfoStatusContainer>
      </StatusContainer>

      <ButtonContainer>
        <CustomButton onClick={() => history.push(redirectTo)} icon={<ChevronRight />} height={56} width="40px"></CustomButton>
      </ButtonContainer>
    </MainContainer>
  );
};

export default DashboardCard;
