import React from 'react';
import { useTheme } from 'styled-components';

interface Props {
  isWhite?: boolean;
  isSecundaryLogo?: boolean;
}

export const Logo: React.FC<Props> = ({ isWhite, isSecundaryLogo }) => {
  const theme = useTheme();

  const color = '#3457F9';
  return isSecundaryLogo ? (
    <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Logo" clip-path="url(#clip0_626_4156)">
        <g id="Group 631823">
          <g id="Group 631822">
            <g id="Group 44">
              <g id="Group 3">
                <mask id="mask0_626_4156" maskUnits="userSpaceOnUse" x="27" y="10" width="11" height="18">
                  <path
                    id="Clip 2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.5648 19.0133L35.8556 27.3176C37.1376 24.7741 37.8592 21.9017 37.8592 18.8615C37.8592 15.9821 37.2122 13.2537 36.0547 10.8123L27.5648 19.0133Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_626_4156)">
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.9772 28.5023L26.4768 19.0345L35.9546 9.58936L45.4545 19.0571L35.9772 28.5023Z"
                    fill="#88B929"
                  />
                </g>
              </g>
              <g id="Group 6">
                <mask id="mask1_626_4156" maskUnits="userSpaceOnUse" x="10" y="27" width="18" height="11">
                  <path
                    id="Clip 5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.6774 35.8445C13.1715 37.0504 15.9715 37.7266 18.9296 37.7266C21.8961 37.7266 24.7028 37.047 27.2021 35.8348L18.9454 27.6077L10.6774 35.8445Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask1_626_4156)">
                  <path
                    id="Fill 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.9327 45.5112L9.42175 36.0326L18.9447 26.5422L28.4554 36.0208L18.9327 45.5112Z"
                    fill="#51ACCF"
                  />
                </g>
              </g>
              <g id="Group 9">
                <mask id="mask2_626_4156" maskUnits="userSpaceOnUse" x="1" y="18" width="18" height="18">
                  <path
                    id="Clip 8"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.98474 27.2805C3.85146 31.0042 6.91862 34.0278 10.6798 35.8459L18.9454 27.6078L10.3057 18.9973L1.98474 27.2805Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask2_626_4156)">
                  <path
                    id="Fill 7"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.0124 27.6081L10.301 17.9297L0.0587158 28.137L9.77032 37.8155L20.0124 27.6081Z"
                    fill="#4F93CB"
                  />
                </g>
              </g>
              <g id="Group 12">
                <mask id="mask3_626_4156" maskUnits="userSpaceOnUse" x="10" y="0" width="18" height="11">
                  <path
                    id="Clip 11"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.5623 0C15.7731 0.0528263 13.1315 0.707226 10.7629 1.83771C10.6003 1.93344 10.5189 1.99123 10.5189 1.99123L18.9455 10.3867L27.3946 2.00417C27.3946 2.00417 27.3486 1.97182 27.2569 1.91512C24.8469 0.736765 22.1497 0.05412 19.2973 0H18.5623Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask3_626_4156)">
                  <path
                    id="Fill 10"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.9633 11.4696L9.45215 1.99103L18.9501 -7.47437L28.4613 2.00419L18.9633 11.4696Z"
                    fill="#4158A1"
                  />
                </g>
              </g>
              <g id="Group 15">
                <mask id="mask4_626_4156" maskUnits="userSpaceOnUse" x="1" y="1" width="18" height="18">
                  <path
                    id="Clip 14"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.89844 10.6175L10.3057 18.9972L18.9455 10.3868L10.4963 1.96753C6.76074 3.82335 3.72633 6.87456 1.89844 10.6175Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask4_626_4156)">
                  <path
                    id="Fill 13"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.3058 20.0601L-0.0135498 9.77601L9.69284 0.102539L20.0121 10.3869L10.3058 20.0601Z"
                    fill="#4A69AB"
                  />
                </g>
              </g>
              <g id="Group 18">
                <mask id="mask5_626_4156" maskUnits="userSpaceOnUse" x="0" y="10" width="11" height="18">
                  <path
                    id="Clip 17"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 18.8617C0 21.8898 0.715932 24.7513 1.98817 27.2874L10.3056 18.9973L1.89968 10.6147C0.682965 13.1058 0 15.9047 0 18.8617Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask5_626_4156)">
                  <path
                    id="Fill 16"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.85194 28.4864L-7.65601 19.011L1.86756 9.51978L11.3755 18.9951L1.85194 28.4864Z"
                    fill="#517DBA"
                  />
                </g>
              </g>
              <g id="Group 21">
                <mask id="mask6_626_4156" maskUnits="userSpaceOnUse" x="18" y="2" width="19" height="18">
                  <path
                    id="Clip 20"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.929 10.4021L27.5672 19.0139L36.0543 10.8116C34.2938 7.09845 31.3533 4.04983 27.7145 2.14657C27.5071 2.05299 27.3878 2.00513 27.3878 2.00513L18.929 10.4021Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask6_626_4156)">
                  <path
                    id="Fill 19"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.1746 19.7653H36.8519V1.25342H18.1746V19.7653Z"
                    fill="#39549E"
                  />
                </g>
              </g>
              <g id="Group 24">
                <mask id="mask7_626_4156" maskUnits="userSpaceOnUse" x="18" y="19" width="18" height="17">
                  <path
                    id="Clip 23"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.9438 27.607L27.1991 35.8361C27.4877 35.6962 27.7721 35.5493 28.0523 35.3954L34.9461 28.9214C35.2999 28.3632 35.6248 27.7856 35.9193 27.1898L27.5613 19.0112L18.9438 27.607Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask7_626_4156)">
                  <path
                    id="Fill 22"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.6438 37.3422L17.8723 27.6043L27.6407 17.8691L37.412 27.6073L27.6438 37.3422Z"
                    fill="#5FB6A1"
                  />
                </g>
              </g>
              <path
                id="Fill 25"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5572 19.0012C27.5572 19.0012 30.1874 21.3256 33.0034 23.455C35.8196 25.5842 38.2548 26.9178 38.2548 26.9178C38.3268 26.9579 38.345 27.0438 38.2938 27.1102C38.2938 27.1102 36.2699 29.8448 33.0034 32.2238C29.7371 34.6027 27.323 35.7737 27.323 35.7737L27.5572 19.0012Z"
                fill="#D9D024"
              />
              <g id="Group 29">
                <mask id="mask8_626_4156" maskUnits="userSpaceOnUse" x="27" y="19" width="12" height="17">
                  <path
                    id="Clip 28"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.323 35.7737C27.323 35.7737 29.7371 34.6024 33.0036 32.2235C36.2699 29.8446 38.294 27.1099 38.294 27.1099C38.345 27.0437 38.327 26.9579 38.2548 26.9176C38.2548 26.9176 35.8196 25.5842 33.0036 23.4548C30.1876 21.3253 27.5572 19.001 27.5572 19.001L27.323 35.7737Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask8_626_4156)">
                  <path
                    id="Fill 27"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-0.588867 38.0815H38.4675V-0.841797H-0.588867V38.0815Z"
                    fill="#EDE21E"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_626_4156">
          <rect width="38.95" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="105" height="30" viewBox="0 0 195 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7836 47.1984C21.3884 47.1984 22.943 47.0225 24.4474 46.6706C26.002 46.2685 27.331 45.8664 28.4342 45.4643C29.0862 45.213 29.5124 45.1376 29.713 45.2381C29.9638 45.2884 30.1894 45.59 30.39 46.1429L32.4963 51.7976C32.747 52.3003 32.8473 52.7024 32.7972 53.004C32.7972 53.3056 32.5214 53.6071 31.9697 53.9087C29.7632 55.1151 27.4062 55.9193 24.8988 56.3214C22.3913 56.7738 20.1096 57 18.0535 57C12.136 57 7.64766 55.291 4.58859 51.873C1.52953 48.455 0 43.328 0 36.4921C0 33.2751 0.426263 30.3849 1.27879 27.8214C2.18146 25.2077 3.43518 22.996 5.03993 21.1865C6.64469 19.377 8.60048 17.9947 10.9073 17.0397C13.2643 16.0847 15.872 15.6071 18.7305 15.6071C20.8367 15.6071 22.9179 15.8333 24.974 16.2857C27.0301 16.6878 28.9107 17.3161 30.6157 18.1706C31.2676 18.4722 31.6187 18.7487 31.6688 19C31.7691 19.2513 31.6939 19.6786 31.4432 20.2817L29.3369 26.0873C29.0862 26.6402 28.8104 26.9418 28.5095 26.9921C28.2086 27.0423 27.7071 26.9669 27.005 26.7659C25.7011 26.3638 24.4224 26.037 23.1686 25.7857C21.9149 25.5344 20.7114 25.4087 19.5579 25.4087C18.0535 25.4087 16.7747 25.7103 15.7216 26.3135C14.7186 26.9167 13.8912 27.7209 13.2392 28.7262C12.5873 29.7315 12.1109 30.9127 11.81 32.2698C11.5091 33.5767 11.3587 34.9841 11.3587 36.4921C11.3587 38.0503 11.484 39.4828 11.7348 40.7897C11.9855 42.0966 12.4368 43.2275 13.0888 44.1825C13.7407 45.1376 14.5932 45.8915 15.6464 46.4444C16.7496 46.9471 18.1287 47.1984 19.7836 47.1984Z"
        fill={isWhite ? '#FFFFFF' : color}
      />
      <path
        d="M51.3578 54.8135C51.3578 55.4167 51.2575 55.7937 51.0569 55.9444C50.8563 56.0952 50.405 56.1706 49.7029 56.1706H42.0302C41.4786 56.1706 41.0774 56.0952 40.8266 55.9444C40.5759 55.7434 40.4505 55.3664 40.4505 54.8135V22.6944C40.4505 20.7341 40.7765 19.2513 41.4284 18.246C42.1305 17.1905 43.5848 16.4868 45.7913 16.1349C46.694 16.0344 47.6218 15.959 48.5746 15.9087C49.5274 15.8082 50.5053 15.7579 51.5083 15.7579C54.8181 15.7579 58.0777 16.1852 61.2872 17.0397C64.5469 17.8942 67.4304 19.2513 69.9379 21.1111C70.3391 21.4127 70.5146 21.7143 70.4644 22.0159C70.4644 22.2672 70.3892 22.5437 70.2388 22.8452L66.3272 29.1032C66.0263 29.6058 65.7505 29.832 65.4997 29.7817C65.249 29.7315 64.8478 29.5304 64.2962 29.1786C62.892 28.123 61.3875 27.2685 59.7828 26.6151C58.178 25.9114 56.3727 25.5344 54.3667 25.4841C53.3136 25.4841 52.3106 25.5595 51.3578 25.7103V54.8135Z"
        fill={isWhite ? '#FFFFFF' : color}
      />
      <path
        d="M90.7194 15.6071C96.5867 15.6071 100.975 17.0899 103.883 20.0556C106.842 22.9709 108.322 27.168 108.322 32.6468C108.322 35.2606 107.82 37.045 106.817 38C105.814 38.955 104.435 39.4325 102.68 39.4325H83.4227C83.4729 41.041 83.7236 42.373 84.175 43.4286C84.6765 44.4841 85.3284 45.3386 86.1308 45.9921C86.9331 46.6455 87.8609 47.0979 88.914 47.3492C90.0173 47.6005 91.1958 47.7262 92.4495 47.7262C93.9038 47.7262 95.3832 47.5503 96.8876 47.1984C98.3921 46.8466 99.7963 46.3942 101.1 45.8413C101.702 45.6402 102.103 45.59 102.304 45.6905C102.554 45.7407 102.78 45.9669 102.981 46.3691L105.388 51.7976C105.588 52.25 105.613 52.6019 105.463 52.8532C105.363 53.1045 105.112 53.3558 104.711 53.6071C102.855 54.8135 100.699 55.668 98.2416 56.1706C95.7844 56.7235 93.5026 57 91.3964 57C84.8269 57 79.9625 55.3664 76.8031 52.0992C73.6438 48.832 72.0641 43.6548 72.0641 36.5675C72.0641 33.25 72.4904 30.3095 73.3429 27.746C74.2456 25.1323 75.4993 22.9206 77.104 21.1111C78.7589 19.3016 80.7147 17.9444 82.9714 17.0397C85.2782 16.0847 87.8609 15.6071 90.7194 15.6071ZM90.945 24.6548C88.8889 24.6548 87.209 25.3836 85.9051 26.8413C84.6012 28.2989 83.849 30.1587 83.6484 32.4206H97.7151C97.7151 31.3148 97.5897 30.2844 97.339 29.3294C97.1384 28.3743 96.7873 27.5701 96.2859 26.9167C95.7844 26.213 95.1074 25.6601 94.2548 25.2579C93.4023 24.8558 92.299 24.6548 90.945 24.6548Z"
        fill={isWhite ? '#FFFFFF' : color}
      />
      <path
        d="M140.469 9.34921C140.368 9.80159 140.193 10.078 139.942 10.1786C139.742 10.2791 139.441 10.2791 139.039 10.1786C138.187 10.0278 137.259 9.90212 136.256 9.80159C135.303 9.70106 134.526 9.6508 133.924 9.6508C132.219 9.6508 131.091 10.0026 130.539 10.7064C130.038 11.3598 129.787 12.1892 129.787 13.1944V16.5873H138.814C139.265 16.5873 139.566 16.6627 139.716 16.8135C139.867 16.9643 139.942 17.291 139.942 17.7937V23.5992C139.942 24.0013 139.867 24.3029 139.716 24.504C139.616 24.705 139.265 24.8056 138.663 24.8056H129.787V54.8135C129.787 55.4167 129.662 55.7937 129.411 55.9444C129.21 56.0952 128.834 56.1706 128.283 56.1706H120.459C119.958 56.1706 119.557 56.0952 119.256 55.9444C119.005 55.7937 118.88 55.3915 118.88 54.7381V24.8056H113.388C112.837 24.8056 112.511 24.705 112.411 24.504C112.31 24.2526 112.26 23.9259 112.26 23.5238V19.2262C112.26 18.7738 112.335 18.4471 112.486 18.246C112.686 17.9947 113.063 17.7937 113.614 17.6429L118.88 16.4365V12.1389C118.88 8.67064 119.833 5.83069 121.738 3.61905C123.694 1.40741 126.828 0.301588 131.141 0.301588C131.492 0.301588 132.044 0.326721 132.796 0.376988C133.548 0.376988 134.376 0.427252 135.278 0.52778C136.181 0.578044 137.109 0.703705 138.062 0.904764C139.065 1.05556 140.017 1.25662 140.92 1.50794C141.472 1.709 141.773 1.93519 141.823 2.18651C141.873 2.38757 141.848 2.68916 141.747 3.09127L140.469 9.34921Z"
        fill={isWhite ? '#FFFFFF' : color}
      />
      <path
        d="M194.624 22.2421C194.624 22.996 194.499 23.75 194.248 24.504C194.047 25.2077 193.671 25.8862 193.119 26.5397L175.066 46.8968L175.216 47.2738H193.571C194.122 47.2738 194.499 47.3743 194.699 47.5754C194.9 47.7262 195 48.078 195 48.631V54.7381C195 55.291 194.9 55.668 194.699 55.8691C194.549 56.0701 194.198 56.1706 193.646 56.1706H164.384C163.131 56.1706 162.203 55.8942 161.601 55.3413C161.049 54.7381 160.774 53.9339 160.774 52.9286V50.6667C160.774 49.9127 160.874 49.1587 161.074 48.4048C161.325 47.6508 161.827 46.8466 162.579 45.9921L180.557 25.6349L180.407 25.3333H164.008C163.406 25.3333 163.03 25.2328 162.88 25.0317C162.729 24.8307 162.654 24.4537 162.654 23.9008V18.0198C162.654 17.0648 163.131 16.5873 164.083 16.5873H190.863C192.116 16.5873 193.044 16.7884 193.646 17.1905C194.298 17.5423 194.624 18.3466 194.624 19.6032V22.2421Z"
        fill={isWhite ? '#FFFFFF' : color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.544 55.9444C155.744 55.7434 155.845 55.3664 155.845 54.8135V17.9444C155.845 17.3915 155.744 17.0397 155.544 16.8889C155.343 16.6878 154.892 16.5873 154.19 16.5873H146.517C145.965 16.5873 145.564 16.6878 145.314 16.8889C145.063 17.0397 144.937 17.3915 144.937 17.9444V54.8135C144.937 55.3664 145.063 55.7434 145.314 55.9444C145.564 56.0952 145.965 56.1706 146.517 56.1706H154.19C154.892 56.1706 155.343 56.0952 155.544 55.9444Z"
        fill={isWhite ? '#FFFFFF' : color}
      />
      <path
        d="M156.823 6.25794C156.823 8.0172 156.196 9.5 154.942 10.7064C153.738 11.8624 152.259 12.4405 150.504 12.4405C148.799 12.4405 147.319 11.8624 146.066 10.7064C144.862 9.5 144.26 8.0172 144.26 6.25794C144.26 5.35318 144.411 4.52381 144.712 3.76984C145.063 3.01587 145.514 2.36244 146.066 1.80953C146.668 1.25662 147.345 0.829366 148.097 0.52778C148.849 0.175927 149.651 0 150.504 0C151.356 0 152.159 0.175927 152.911 0.52778C153.713 0.829366 154.39 1.25662 154.942 1.80953C155.544 2.36244 155.995 3.01587 156.296 3.76984C156.647 4.52381 156.823 5.35318 156.823 6.25794Z"
        fill="#10ECEA"
      />
    </svg>
  );
};
