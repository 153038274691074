import { ReactComponent as CreditIconSvg } from 'images/analytics.svg';
import { ReactComponent as BranchIconSvg } from 'images/briefcaseWhite.svg';
import { ReactComponent as AnticipationIconSvg } from 'images/clock.svg';
import { ReactComponent as RegisterIconSvg } from 'images/document-new.svg';
import { ReactComponent as SignatureIconSvg } from 'images/edit-3.svg';
import { ReactComponent as MyTeamIconSvg } from 'images/group-access.svg';
import { ReactComponent as ProductsIconSvg } from 'images/layers.svg';
import { ReactComponent as ContratsIconSvg } from 'images/license-draft.svg';
import { ReactComponent as UserIconSvg } from 'images/user.svg';
import { ReactComponent as ReportIconSvg } from 'images/version.svg';
import { ReactComponent as GridIconSvg } from 'images/workspace.svg';
import { FunctionComponentElement } from 'react';
const BASE_URL = '/admin';

export interface Tab {
  logo?: FunctionComponentElement<SVGSVGElement>;
  label?: string;
  url?: string;
}

export const DEFAULT_TABS: Tab[] = [
  {
    logo: <GridIconSvg />,
    label: 'admin.dashboard.initial',
    url: `${BASE_URL}/dashboard`,
  },
  {
    logo: <RegisterIconSvg />,
    label: 'admin.dashboard.register',
    url: `${BASE_URL}/cadastros`,
  },
  {
    logo: <ContratsIconSvg />,
    label: 'admin.dashboard.contracts',
    url: `${BASE_URL}/contratos`,
  },
];

export const CUSTOM_TABS = {
  anticipationTab: {
    logo: <AnticipationIconSvg />,
    label: 'admin.dashboard.anticipations',
    url: `${BASE_URL}/antecipacoes`,
  },
  procedureTab: {
    logo: <ProductsIconSvg />,
    label: 'admin.dashboard.procedure',
    url: `${BASE_URL}/procedimentos`,
  },
  creditTab: {
    logo: <CreditIconSvg />,
    label: 'admin.dashboard.creditReviews',
    url: `${BASE_URL}/creditos`,
  },
  myTeamTab: {
    logo: <MyTeamIconSvg />,
    label: 'admin.dashboard.myTeam',
    url: `${BASE_URL}/minha-equipe`,
  },
  signaturesTab: {
    logo: <SignatureIconSvg />,
    label: 'admin.dashboard.signatures',
    url: `${BASE_URL}/assinaturas`,
  },
  reportTab: {
    logo: <ReportIconSvg />,
    label: 'admin.dashboard.report',
    url: `${BASE_URL}/relatorio`,
  },
  branchTab: {
    logo: <BranchIconSvg />,
    label: 'admin.dashboard.branch',
    url: `${BASE_URL}/filial`,
  },
  myAccountTab: {
    logo: <UserIconSvg />,
    label: 'admin.dashboard.myAccount',
    url: `${BASE_URL}/minha-conta`,
  },
  reportCcb: {
    logo: <ReportIconSvg />,
    label: 'admin.dashboard.reportCcb',
    url: `${BASE_URL}/relatorio-ccb`,
  },
};
