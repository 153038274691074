import { CustomSideMenu } from 'components/custom-side-menu';
import DashboardHeader from 'features/admin/dashboard/components/dashboard-header';
import { useTranslation } from 'react-i18next';
import ContractScreen from '../..';
import { Container, Content, HeaderContainer, PageContainer } from './styles';

const HandleSideMenu = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Container>
        <CustomSideMenu />
        <HeaderContainer>
          <DashboardHeader title={t('admin.contractScreen.title')} currentScreenPath="" />
          <Content>
            <ContractScreen />
          </Content>
        </HeaderContainer>
      </Container>
    </PageContainer>
  );
};

export default HandleSideMenu;
