import contractApi from 'api/contract-api';
import { Contract } from 'model/financing';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import contractService from 'services/contract-service';
import financingService from 'services/financing-service';
import { getNameMonthPtbr } from 'shared/util/date-utils';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { Desktop } from './layout/desktop';
import { Mobile } from './layout/mobile';

interface DateReturn {
  day: string;
  month: string;
  year: string;
}

const ContractScreen = () => {
  const isMobile = useResponsiveMobile().isTablet;
  const { t } = useTranslation();
  const { contractId } = useParams<{ contractId: string }>();
  const [tabSelected, setTabSelected] = useState(1);
  const [contract, setContract] = useState<Contract | null>(null);
  const [loading, setLoading] = useState(false);
  const [signatureLink, setSignatureLink] = useState<string | null>(null);
  const [financingId, setFinancingId] = useState<number | undefined>(undefined);
  const [financingCodes, setFinancingCodes] = useState<{ id?: string; ccb?: string } | null>(null);

  useEffect(() => {
    getContractInfos();
  }, []);

  useEffect(() => {
    getFinancingCode();
  }, [financingId]);

  const getContractInfos = async () => {
    if (contractId) {
      const res = await contractService.getContractById(Number(contractId));
      setContract(res);
      res?.financing != undefined ? setFinancingId(res.financing.id) : '';

      if (res?.contractSign?.contractSignatories?.[0]?.signatureLink != null) {
        setSignatureLink(res.contractSign.contractSignatories[0].signatureLink);
      }
    }
  };

  const getFinancingCode = async () => {
    await financingService.getFinancingById(Number(financingId)).then(response => {
      setFinancingCodes({
        id: response?.identification,
        ccb: response.ccb,
      });
    });
  };

  const downloadPdf = (attchUrl?: string) => {
    if (attchUrl && contract) {
      fetch(attchUrl)
        .then(res => res.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${contract.attachment.fileName}.pdf` ?? '');
          document.body.appendChild(link);
          link.click();
          if (link?.parentNode) {
            link?.parentNode?.removeChild(link);
          }
        });
    }
  };

  const getDate = (): DateReturn => {
    if (contract?.contractSign?.sendToSignDate) {
      const date = new Date(contract?.contractSign?.sendToSignDate);
      const dateReturn: DateReturn = {
        day: date.getUTCDate().toString(),
        month: getNameMonthPtbr(date),
        year: date.getUTCFullYear().toString(),
      };

      return dateReturn;
    }

    return {
      day: '',
      month: '',
      year: '',
    };
  };

  const resendEmail = async () => {
    if (contract?.id) {
      await contractApi.resendEmail(contract?.id);
    }
    setLoading(false);
  };

  return !isMobile ? (
    <Desktop
      pageIdentification={financingCodes?.id ?? ''}
      contract={contract}
      date={getDate()}
      tabSelected={tabSelected}
      setTabSelected={setTabSelected}
      downloadPdf={downloadPdf}
      resendEmail={resendEmail}
      isLoading={loading}
      setIsLoading={setLoading}
      signatureLink={signatureLink}
    />
  ) : (
    <Mobile
      contract={contract}
      date={getDate()}
      tabSelected={tabSelected}
      setTabSelected={setTabSelected}
      downloadPdf={downloadPdf}
      resendEmail={resendEmail}
      isLoading={loading}
      setIsLoading={setLoading}
      signatureLink={signatureLink}
    />
  );
};

export default ContractScreen;
