import { GeneralImages } from 'images/general-images';
import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';
import AuthUtils from 'shared/util/auth-utils';
import StringUtils from 'shared/util/string-utils';
import VerifyModal from '../modals';
import { LoggedUserBox, ProfileImage, StyledText } from '../styles';
import { DropdownContainer, DropdownItem, DropdownText, Separator } from './style';

export interface ListType {
  icon?: ReactElement;
  text?: string;
  link?: string;
  modalType?: string;
}

export interface UserDropdownProps {
  list?: ListType[];
  position?: PopupPosition;
  menuOpen?: boolean;
}

const UserDropdown: React.FC<UserDropdownProps> = ({ menuOpen = true, list, position }) => {
  const history = useHistory();

  const [closeOnDocumentClick, setCloseOnDocumentClick] = useState(true);
  const account = AuthUtils.getAccount();

  return (
    <>
      <Popup
        trigger={
          <LoggedUserBox menuOpen={menuOpen} isAuth={AuthUtils.isAuthenticated()}>
            <ProfileImage src={account?.imageUrl ?? GeneralImages.iconDefault} />
            <StyledText menuOpen={menuOpen}>{StringUtils.getFirstName(account?.firstName ?? '')}</StyledText>
          </LoggedUserBox>
        }
        position={position ? position : 'bottom center'}
        closeOnDocumentClick={closeOnDocumentClick}
        on="click"
        arrow={false}
      >
        {closeMenu => (
          <DropdownContainer>
            {list?.map(
              (item, idx, arr) =>
                item?.text != null && (
                  <div key={idx}>
                    {item.modalType ? (
                      <>
                        <VerifyModal
                          text={item.text}
                          icon={item.icon}
                          modalType={item.modalType}
                          setCloseOnDocumentClick={setCloseOnDocumentClick}
                          closeMenu={closeMenu}
                        />
                        <Separator isLast={idx === arr.length - 1} />
                      </>
                    ) : (
                      <>
                        <DropdownItem onClick={() => history.push(item?.link ?? '')}>
                          {item.icon ?? ''}
                          <DropdownText>{item.text}</DropdownText>
                        </DropdownItem>
                        <Separator isLast={idx === arr.length - 1} />
                      </>
                    )}
                  </div>
                )
            )}
          </DropdownContainer>
        )}
      </Popup>
    </>
  );
};

export default UserDropdown;
