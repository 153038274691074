import AdminFoundedDataContainer from 'components/landing-page/found-data-admin';
import ConfirmSimulation from 'components/simulator-data/confirm-simulation';
import SimulatorNotApproved from 'components/simulator-data/not-approved';
import ProcessingSimulator from 'components/simulator-data/processing';
import { SignaturesScreen } from 'features/admin/SignaturesScreen';
import { AddNewAnalysis } from 'features/admin/addNewAnalysis';
import { AddProcedureScreen } from 'features/admin/addProcedureScreen';
import { AuthenticationScreen } from 'features/admin/authenticationScreen';
import { BankAccountScreen } from 'features/admin/bankAccountScreen';
import { BiometryScreen } from 'features/admin/biometryScreen';
import BranchsScreen from 'features/admin/branchScreen';
import { ClientDataScreen } from 'features/admin/clientData';
import adminAddNewFinancing from 'features/admin/clientData/admin-add-new-financing';
import EditProfileAdmin from 'features/admin/clientData/edit';
import HandleSideMenu from 'features/admin/contractScreen/components/handle';
import CreateBranch from 'features/admin/createBranchScreen';
import CreditCardScreen from 'features/admin/creditCardScreen';
import { CreditCardSummaryScreen } from 'features/admin/creditCardSummaryScreen';
import CreditAnalysisScreen from 'features/admin/customerAnalysis/components/analysis';
import { AdminDashboard } from 'features/admin/dashboard';
import { DTIScreen } from 'features/admin/dtiScreen';
import { GuarantorsList } from 'features/admin/guarantorsList';
import InsuranceScreen from 'features/admin/insuranceScreen';
import MyAccount from 'features/admin/myAccount';
import NewBalanceScreen from 'features/admin/new-balance-screen';
import { PaymentScreen } from 'features/admin/paymentScreen';
import { ProcedureScreen } from 'features/admin/procedureScreen';
import { SimulatorApprovedModal } from 'features/admin/simulatorApprovedModal';
import { simulatorRejectModal } from 'features/admin/simulatorRejectModal';
import VaduScreen from 'features/admin/vaduScreen';
import { Authority } from 'model/enums/authority';
import { AdminProvider } from 'provider/admin-general';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoutes, { adminAccessLevels, internalAccessLevels } from '../private-routes';

const generalAccessLevels = [...adminAccessLevels, ...internalAccessLevels];

export const AdminRoutes = ({ match }) => {
  return (
    <Switch>
      <AdminProvider>
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}`} component={AdminDashboard}>
          <Redirect to={`${match.url}/dashboard`} />
        </PrivateRoutes>
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/dashboard`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/cadastros`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/creditos`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/contratos`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/pagamentos`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/procedimentos`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/assinaturas`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/assinaturas/:id`} component={SignaturesScreen} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/filial`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/criar-filial`} component={CreateBranch} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/filial/:id`} component={BranchsScreen} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/minha-conta`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/filial/conta/:id`} component={MyAccount} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/relatorio-ccb`} component={AdminDashboard} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/perfil`} component={AdminDashboard} />

        <PrivateRoutes
          accessLevel={[...adminAccessLevels, Authority.ROLE_MANAGER]}
          exact
          path={`${match.url}/minha-equipe`}
          component={AdminDashboard}
        />
        <PrivateRoutes
          accessLevel={[...adminAccessLevels, Authority.ROLE_MANAGER]}
          exact
          path={`${match.url}/relatorio`}
          component={AdminDashboard}
        />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/cadastros/:id`} component={ClientDataScreen} />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/cadastros/:id/cartao/:creditCardId`}
          component={CreditCardSummaryScreen}
        />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/cadastros/editar/:id`} component={EditProfileAdmin} />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/creditos/analise/:financingId`}
          component={CreditAnalysisScreen}
        />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/contratos/analise/:contractId`}
          component={HandleSideMenu}
        />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/autenticacao/:id`} component={AuthenticationScreen} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/conta-bancaria/:id`} component={BankAccountScreen} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/biometria/:id`} component={BiometryScreen} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/dti/:id`} component={DTIScreen} />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/cartao/:financeId/:clientId`}
          component={CreditCardScreen}
        />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/fiadores/:id`} component={GuarantorsList} />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/pagamentos/analise/:financingId`}
          component={PaymentScreen}
        />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/novo-procedimento/:id`} component={AddProcedureScreen} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/nova-analise/:id`} component={AddNewAnalysis} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/novo-cliente`} component={adminAddNewFinancing} />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/dados-encontrados-admin`}
          component={AdminFoundedDataContainer}
        />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/procedimentos/analise/:procedureId`}
          component={ProcedureScreen}
        />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/processando-simulador/:clientId`}
          component={ProcessingSimulator}
        />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/finalizando-simulador/:clientId`}
          component={ProcessingSimulator}
        />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/simulador-reprovado`} component={SimulatorNotApproved} />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/confirmar-simulacao/:clientId`}
          component={ConfirmSimulation}
        />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/simulador-aprovado/:clientId`}
          component={SimulatorApprovedModal}
        />
        <PrivateRoutes
          accessLevel={generalAccessLevels}
          exact
          path={`${match.url}/simulador-reprovado/:clientId`}
          component={simulatorRejectModal}
        />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/seguro/:financingId`} component={InsuranceScreen} />
        <PrivateRoutes accessLevel={generalAccessLevels} exact path={`${match.url}/vadu/:clientId`} component={VaduScreen} />
        <PrivateRoutes accessLevel={generalAccessLevels} path={`${match.url}/novo-saldo`} component={NewBalanceScreen} />
      </AdminProvider>
    </Switch>
  );
};
