import { PageLoading } from 'components/page-loading';
import { ReactComponent as CreditIconSvg } from 'images/analytics.svg';
import { ReactComponent as RegisterIconSvg } from 'images/document-new.svg';
import { ReactComponent as ContratsIconSvg } from 'images/license-draft.svg';
import { InitialDashboard } from 'model/dashboard';
import StepType from 'model/enums/step-type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { DashboardService } from 'services/dashboard-service';
import DashboardCard from '../../components/dashboard-card';
import WelcomeImage from '../../components/dashboard-card/welcome-image';
import { CardsContainer, QickAccessTitle, WelcomeCardContainer, WelcomeTitle } from './styles';

const baseUrl = '/admin';

interface InitialDashboardProps {
  orgStepTypes: StepType[] | null;
}

export const InitialDashboardScreen = ({ orgStepTypes }: InitialDashboardProps) => {
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [userName, setUserName] = useState('');

  const [initialInfos, setInitialInfos] = useState<null | InitialDashboard>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (account?.firstName) {
      setUserName(account.firstName);
    }
  }, []);

  useEffect(() => {
    if (orgStepTypes != null) {
      if (orgStepTypes.includes(StepType.PROCEDURE) && !orgStepTypes.includes(StepType.CCB)) {
        getInitialDashboardInfos(StepType.PROCEDURE);
      }

      if (orgStepTypes.includes(StepType.CCB)) {
        getInitialDashboardInfos();
      }
    }
  }, [orgStepTypes]);

  const getInitialDashboardInfos = async (financingType?: StepType) => {
    const data = await DashboardService().getInitialDashboardInfos(financingType);
    setInitialInfos(data);
  };

  if (initialInfos == null) {
    return <PageLoading customStyle={{ width: '100%' }} />;
  }

  return (
    <>
      <WelcomeCardContainer>
        <WelcomeTitle>
          <h3>Olá, {userName}!</h3>
          <p>{t('admin.dashboard.welcomeMessage')}</p>
        </WelcomeTitle>

        <WelcomeImage />
      </WelcomeCardContainer>

      <CardsContainer>
        <QickAccessTitle> {t('admin.dashboard.qickAccess')} </QickAccessTitle>
        <DashboardCard
          mainTitle={t('admin.dashboard.register')}
          mainTotal={initialInfos?.client.total}
          firstStatus={t('admin.dashboard.statusQuantity.new')}
          firstStatusTotal={initialInfos.client.statusNew}
          secondStatus={t('admin.dashboard.statusQuantity.updated')}
          secondStatusTotal={initialInfos.client.adjusted}
          thirdStatus={t('admin.dashboard.statusQuantity.validation')}
          thirdStatusTotal={initialInfos.client.validation}
          icon={<RegisterIconSvg />}
          redirectTo={`${baseUrl}/cadastros`}
        />
        <DashboardCard
          mainTitle={t('admin.dashboard.contracts')}
          mainTotal={initialInfos?.contract.total}
          firstStatus={t('admin.dashboard.statusQuantity.new')}
          firstStatusTotal={initialInfos?.contract.statusNew}
          secondStatus={t('admin.dashboard.statusQuantity.inProgress')}
          secondStatusTotal={initialInfos?.contract.inProgress}
          thirdStatus={t('admin.dashboard.statusQuantity.finished')}
          thirdStatusTotal={initialInfos?.contract.finished}
          icon={<ContratsIconSvg />}
          redirectTo={`${baseUrl}/contratos`}
        />
        <DashboardCard
          mainTitle={t('admin.dashboard.creditReviews')}
          mainTotal={initialInfos?.financing.total}
          firstStatus={t('admin.dashboard.statusQuantity.new')}
          firstStatusTotal={initialInfos?.financing.statusNew}
          secondStatus={t('admin.dashboard.statusQuantity.inProgress')}
          secondStatusTotal={initialInfos?.financing.inProgress}
          thirdStatus={t('admin.dashboard.statusQuantity.validation')}
          thirdStatusTotal={initialInfos?.financing.validation}
          icon={<CreditIconSvg />}
          redirectTo={`${baseUrl}/creditos`}
        />
      </CardsContainer>
    </>
  );
};
