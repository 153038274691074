import styled from 'styled-components/macro';

export const ContainerFilterButton = styled.div`
  width: 1004px;
  height: 64px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 64px;
  display: flex;
`;

export const Container = styled.div``;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

export const ActiveFilter = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 9999px;
  border: 1px solid var(--Other-i, #8089ab);
  padding: 0px 8px;
  cursor: pointer;
`;
