import { useEffect, useState } from 'react';
import TableListFilterName from '../table-list-filter-name';
import TableListFilterStatus from '../table-list-filter-status';

import TableListFilterBar from '../table-list-filter-bar';
import TableListFiltersCalendar from '../table-list-filter-calendar';
import { Container, ContainerFilterButton, Overlay } from './styles';

interface StatusTypeFilter {
  label: string;
  value: string;
}

interface TableListFiltersProps {
  values: string[];
  setInitialDate?: (value: string) => void;
  setEndDate?: (value: string) => void;
  initialDate?: string;
  onClickFilterDate: () => void;
  searchName?: string;
  setSearchName?: (value: string) => void;
  setIsFilterName?: (value: boolean) => void;
  onClick?: () => void;
  setFilterStatus?: (value: string) => void;
  filterStatus?: string;
  resetFilters?: () => void;
  statusOptions?: StatusTypeFilter[];
}

const TableListFilters: React.FC<TableListFiltersProps> = ({
  searchName,
  setSearchName,
  values,
  setEndDate,
  setInitialDate,
  onClickFilterDate,
  setIsFilterName,
  onClick,
  setFilterStatus,
  initialDate,
  filterStatus,
  resetFilters,
  statusOptions,
}) => {
  const [showNameFilter, setShowNameFilter] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    if (showDateFilter || showNameFilter || showStatusFilter) {
      setShowDateFilter(false);
      setShowNameFilter(false);
      setShowStatusFilter(false);
      setLoading(false);
    }
  };

  useEffect(() => {}, [searchName]);

  return (
    <Container>
      <ContainerFilterButton>
        <TableListFilterBar
          setEndDate={setEndDate}
          setInitialDate={setInitialDate}
          initialDate={initialDate}
          searchName={searchName}
          setSearchName={setSearchName}
          setFilterStatus={setFilterStatus}
          filterStatus={filterStatus}
          resetFilters={resetFilters}
          showNameFilter={showNameFilter}
          setShowNameFilter={setShowNameFilter}
          showDateFilter={showDateFilter}
          setShowDateFilter={setShowDateFilter}
          showStatusFilter={showStatusFilter}
          setShowStatusFilter={setShowStatusFilter}
        />
      </ContainerFilterButton>

      {showNameFilter && (
        <>
          <Overlay />
          <TableListFilterName
            onCloseModal={closeModal}
            searchName={searchName}
            setSearchName={setSearchName}
            values={values}
            setIsFilterName={setIsFilterName}
            onClick={onClick}
            showNameFilter={showNameFilter}
          />
        </>
      )}

      {showStatusFilter && (
        <>
          <Overlay />
          <TableListFilterStatus
            statusOptions={statusOptions}
            setFinancingStatus={setFilterStatus}
            onCloseModal={closeModal}
            showStatusFilter={showStatusFilter}
          />
        </>
      )}

      {showDateFilter && (
        <>
          <Overlay />
          <TableListFiltersCalendar
            setInitialDate={setInitialDate}
            setEndDate={setEndDate}
            onClickFilterDate={onClickFilterDate}
            searchName={searchName}
            closeModal={closeModal}
            showDateFilter={showDateFilter}
          />
        </>
      )}
    </Container>
  );
};

export default TableListFilters;
