import styled from 'styled-components/macro';

interface SerachResponseContentProps {
  marginTop?: number;
}

export const FilterNameContainer = styled.div`
  width: 500px;
  height: 267px;
  background-color: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 400;
  text-align: center;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  z-index: 1;
`;

export const ModalFilterName = styled.div`
  ${(props: { expanded: boolean }) => null}
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: ${(props: { expanded: boolean }) => (props.expanded ? '' : 'none')};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  opacity: ${(props: { expanded: boolean }) => (props.expanded ? '1' : '0')};
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: 500ms;

  input {
    width: 394.3px;
    padding: 0px;
  }
`;

export const FilterNameButtonContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 32px;

  button {
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
`;

export const SerachResponseContainer = styled.div<SerachResponseContentProps>`
  width: 420px;
  max-height: 192px;
  flex-shrink: 0;
  overflow: auto;
  z-index: 1;
  position: fixed;
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}px`};
  background-color: #fff;
  border-radius: 16px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SerachResponseContent = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 16px;
  }
`;

export const SeparatorInput = styled.div`
  width: 100%;
  height: 1px;
  background: #dde1e6;
`;

export const InputContainer = styled.div`
  width: 420px;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  height: 48px;
  border: 1px solid #999999;
  border-radius: 8px;
  min-width: 280px;

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #333333;
    border: none;
    width: 100%;
  }
`;

export const Labelcontainer = styled.div`
  width: 415px;
  display: flex;
  font-size: 16px;
  font-weight: 600;
`;
