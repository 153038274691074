import styled, { css } from 'styled-components';

interface Props {
  selected?: boolean;
  menuOpen?: boolean;
}

interface PropsMenuOpen {
  menuOpen?: boolean;
}

export const MainContainer = styled.div`
  max-width: 292px;
`;

export const Container = styled.div<PropsMenuOpen>`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  background-color: ${({ theme }) => theme.color.primaryColor};
  width: ${({ menuOpen }) => (menuOpen ? '292px' : '96px')};
  height: 100vh;
  transition: all 0.5s ease;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div<PropsMenuOpen>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  flex: 1;
  padding-left: ${({ menuOpen }) => (menuOpen ? '36px' : '24px')};
  padding-right: ${({ menuOpen }) => (menuOpen ? '36px' : '24px')};
`;

export const ContainerUser = styled.div`
  margin-bottom: 25px;
`;

export const TabButton = styled.button<Props>`
  display: flex;
  align-items: center;
  align-self: stretch;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.white};
  text-align: left;
  min-height: 48px;
  gap: 8px;
  overflow: hidden;
  transition: all 0.5s ease;
  padding-left: ${({ menuOpen }) => (menuOpen ? '20px' : '14px')};
  border-radius: ${({ menuOpen }) => (menuOpen ? '8px' : '99px')};
  width: ${({ menuOpen }) => (menuOpen ? '250x' : '48px')};

  ${({ selected }) =>
    selected &&
    css`
      width: 100%
      color: #fff;
      background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
    `};

  :hover {
    background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
  }
`;

export const ContainerArrow = styled.div<PropsMenuOpen>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: ${({ menuOpen }) => (menuOpen ? '292px' : '64px')};
  height: ${({ menuOpen }) => (menuOpen ? '136px' : '64px')};
  background-image: ${({ menuOpen }) => (menuOpen ? '' : 'linear-gradient(rgb(0 0 0/40%) 0 0)')};
  border-radius: ${({ menuOpen }) => (menuOpen ? '' : '9999px')};
  margin: 0 auto;
  margin-bottom: ${({ menuOpen }) => (menuOpen ? '0px' : '40px')};
`;

export const StyledArrow = styled.div<PropsMenuOpen>`
  width: 32px;
  height: 32px
  display: flex;
  transform: rotate(0deg);
  padding-left: 24px;
  padding-top: 24px;

  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: 35px;

  :hover {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
  }
`;
