import styled from 'styled-components/macro';

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 1004px;
  height: 72px;
  border-radius: 16px;
  background: ${({ theme }) => theme.color.lpColors.whatIsScreen};
  cursor: pointer;
`;

export const MainTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 20px;

  p {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: ${({ theme }) => theme.color.primaryColor};
  }

  span {
    color: #4d5358;
    font-weight: 400;
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 16px;
  background: ${({ theme }) => theme.color.primaryColor};
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: 38px;
`;

export const InfoStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  font-size: 16px;
  gap: 8px;

  p {
    font-size: 16px;
    margin: 0;
    padding: 0;
    color: #878d96;
    font-weight: 600;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    color: #4d5358;
  }
`;

export const Separator = styled.div`
  width: 4px;
  height: 36px;
  border-radius: 3px;
  background: ${({ theme }) => theme.color.primaryColor};
  margin-left: 32px;
  margin-right: 32px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 204px;
  margin-left: 12px;
`;

export const ButtonContainer = styled.div`
  width: 40px;
  height: 56px;

  button {
    border-radius: 8px;
    padding: 15px 8px 17px 8px;
  }
`;
