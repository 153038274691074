import registerApi from 'api/register-api';
import { Address, City, State } from 'model/address';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useFormAddressEvolve = () => {
  const { onClickNext, initialClientData } = useRegisterForm();
  const { states, cities, getCities } = useSelectLists();
  const { t } = useTranslation();

  const schema = yup.object({
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
  });

  useEffect(() => {
    const uf = initialClientData?.address?.state?.acronym;
    const city = initialClientData?.address?.city?.name;
    if (uf && city) {
      getStateAndCityId(uf, city);
    }
  }, []);

  const getStateAndCityId = async (uf: string, city: string) => {
    const stateRes = await registerApi.getStates(7, 0, '', uf);
    const stateId = states.find(state => state.name === uf)?.id;
    if (stateId) {
      getCities(stateId, { name: city, page: 0, size: 7 }, true);
    } else {
      getCities(stateRes.data.content[0].id, { name: city, page: 0, size: 7 }, true);
    }
  };

  const handleSubmit = res => {
    getStateAndCityId(res.state, res.city);
    if (onClickNext) {
      const state = {
        name: res.state,
        id: states.find(state => state.name === res.state)?.id,
        acronym: states.find(state => state.name === res.state)?.acronym,
      } as State;

      const city = {
        name: res.city,
        id: cities.find(city => city.name === res.city)?.id,
        state: state,
      } as City;

      const address: Address = {
        ...initialClientData?.address,
        zipcode: res.zipcode,
        street: res.street,
        number: res.number,
        complement: res.complement,
        district: res.district,
        reference: res.reference,
        city,
        state,
      };

      onClickNext({ address } as Client);
    }
  };

  return {
    schema,
    handleSubmit,
  };
};
