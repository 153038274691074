import CustomButton from 'components/custom-button';
import { useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import CustomCalendar from '../calendar';

import { useTranslation } from 'react-i18next';
import {
  ButtonFilter,
  CalendarContainer,
  FilterDateContainer,
  FilterDateFooter,
  FilterDateHeader,
  ModalContainer,
  Overlay,
  Separator,
  TitleContainer,
} from './styles';

interface TableListFiltersProps {
  setInitialDate?: (value: string) => void;
  setEndDate?: (value: string) => void;
  onClickFilterDate: () => void;
  searchName?: string;
  closeModal: () => void;
  showDateFilter: boolean;
}

const TableListFiltersCalendar: React.FC<TableListFiltersProps> = ({
  showDateFilter,
  closeModal,
  setEndDate,
  setInitialDate,
  onClickFilterDate,
}) => {
  const { t } = useTranslation();
  const [dateValues, setDateValues] = useState<DateObject[]>([]);

  const handleFilterDate = () => {
    const dates = dateValues.map(dateObj => dateObj.format('YYYY-MM-DD'));
    if (setInitialDate && setEndDate && dates.length === 2) {
      setInitialDate(dates[0]);
      setEndDate(dates[1]);
    }

    if (setInitialDate && setEndDate && dates.length === 1) {
      setInitialDate(dates[0]);
      setEndDate(dates[0]);
    }
  };

  useEffect(() => {
    handleFilterDate();
  }, [dateValues]);

  return (
    <>
      <Overlay />

      <FilterDateContainer>
        <ModalContainer expanded={showDateFilter}>
          <TitleContainer>
            <span>{t('global.button.selectDate')}</span>
          </TitleContainer>

          <FilterDateHeader>
            <ButtonFilter>{t('global.button.period')}</ButtonFilter>
            <ButtonFilter>{t('global.button.initial')}</ButtonFilter>
            <ButtonFilter>{t('global.button.end')}</ButtonFilter>
          </FilterDateHeader>

          <Separator />
          <CalendarContainer>
            <CustomCalendar dateValues={dateValues} setDateValues={setDateValues} />
          </CalendarContainer>

          <Separator />
          <FilterDateFooter>
            <CustomButton isInvertColor height={48} width="140px" onClick={() => closeModal()}>
              {t('global.button.cancel')}
            </CustomButton>
            <CustomButton
              height={48}
              width="140px"
              onClick={() => {
                handleFilterDate();
                onClickFilterDate();
                closeModal();
              }}
            >
              {t('global.button.apply')}
            </CustomButton>
          </FilterDateFooter>
        </ModalContainer>
      </FilterDateContainer>
    </>
  );
};

export default TableListFiltersCalendar;
