import { ORGANIZATION_TYPE } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import _, { truncate } from 'lodash';
import { Client } from 'model/client';
import { CreatedDates } from 'model/date';
import { OrganizationType } from 'model/enums/organization-type';
import { RegistrationStatus } from 'model/enums/registration-status';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { getStorageFilter } from 'shared/util/filter-utils';
import { Status } from '../../components/status';
import { TableId, TableList, TableValues } from '../../components/table-list';
import TableListFilters from '../../components/table-list-filters';

interface StatusTypeFilter {
  label: string;
  value: string;
}

export const RegistersList = () => {
  const pageSizeDefault = 8;
  const [clients, setClients] = useState<Client[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const { t } = useTranslation();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;
  const [orderBy, setOrderBy] = useState<boolean>(false);
  const [containsNextPage, setContainsNextPage] = useState(false);
  const [pageSize, setPageSize] = useState(pageSizeDefault);
  const [sortColumn, setSortColumn] = useState('');
  const [filterClient, setFilterClient] = useState<Client[]>([]);
  const [isFilterName, setIsFilterName] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [clientStatus, setClientStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const { filters } = useContext(AdminFiltersContext);
  const [viewMore, setViewMore] = useState<boolean>(false);

  const registrationStatus: StatusTypeFilter[] = [
    { label: t(`enum.registrationStatus.${RegistrationStatus.NEW}`), value: RegistrationStatus.NEW },
    { label: t(`enum.registrationStatus.${RegistrationStatus.VALIDATION}`), value: RegistrationStatus.VALIDATION },
    { label: t(`enum.registrationStatus.${RegistrationStatus.ADJUSTED}`), value: RegistrationStatus.ADJUSTED },
    { label: t(`enum.registrationStatus.${RegistrationStatus.ADJUST}`), value: RegistrationStatus.ADJUST },
    { label: t(`enum.registrationStatus.${RegistrationStatus.APPROVED}`), value: RegistrationStatus.APPROVED },
    { label: t(`enum.registrationStatus.${RegistrationStatus.REJECTED}`), value: RegistrationStatus.REJECTED },
    { label: t(`enum.registrationStatus.${RegistrationStatus.PRE_REJECTED}`), value: RegistrationStatus.PRE_REJECTED },
    { label: t(`enum.registrationStatus.${RegistrationStatus.UPDATED}`), value: RegistrationStatus.UPDATED },
  ];

  useEffect(() => {
    verifyStorageAndGetClients();
  }, [clientStatus, searchName, pageSize, page, orderBy, searchSubsidiaryName, filters, sortColumn, initialDate, endDate]);

  const verifyStorageAndGetClients = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }
    getDashboardClients({
      name: searchName,
      page,
      registrationStatus: defaultFilters,
      createdDates,
      subsidiaryName: searchSubsidiaryName,
      clientStatus: clientStatus,
    });
  };

  const getDashboardClients = async (props?: {
    name?: string;
    size?: number;
    page?: number;
    registrationStatus?: string[];
    createdDates?: CreatedDates;
    subsidiaryName?: string;
    clientStatus?: string;
  }) => {
    if (!viewMore) setLoading(true);

    const res = await DashboardService().getClientsList(
      props?.name,
      pageSize,
      props?.page,
      orderBy,
      props?.subsidiaryName,
      sortColumn,
      clientStatus,
      initialDate,
      endDate
    );

    if (res && !viewMore) setLoading(false);
    isFilterName ? setFilterClient(res.content) : setClients(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
    setContainsNextPage(res.size < res.totalElements);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: isPhysicalPerson ? t('admin.dashboard.tableHeaders.name') : t('admin.dashboard.tableHeaders.corporateName'),
        values: isPhysicalPerson
          ? clients.map(client => truncate(client.physicalPerson?.name, { length: 20 }) ?? '')
          : clients.map(client => truncate(client.legalPerson?.corporateName, { length: 20 }) ?? ''),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
        sortColumn: 'physicalPerson.name',
        paddingLeft: '10px',
      },
      {
        header: t('admin.dashboard.tableHeaders.email'),
        values: clients.map(client => truncate(client.email, { length: 20 }) ?? ''),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
        sortColumn: 'email',
      },
      {
        header: t('admin.dashboard.tableHeaders.registrationDate'),
        values: clients.map(client => formatDateToLocalFormat(client.createdDate)),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
        sortColumn: 'createdDate',
      },
      {
        header: t('admin.dashboard.tableHeaders.atualizationDate'),
        values: clients.map(client => formatDateToLocalFormat(client.lastModifiedDate)),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
        sortColumn: 'lastModifiedDate',
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: clients.map(client => (
          <Status
            key={client.id}
            text={t(`enum.registrationStatus.${client.registrationStatus}`)}
            color={client.registrationStatus ?? ''}
          />
        )),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
        sortColumn: 'registrationStatus',
        showMore: 'Ver mais',
      },
    ];

    return tableValues;
  };

  const updateSearchName = _.debounce((name: string) => {
    setSearchName(name);
  }, 500);

  const updateSubsidiaryName = _.debounce((name: string) => {
    setSearchSubsidiaryName(name);
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const setNewFilter = () => {
    getDashboardClients({ name: searchName, registrationStatus: statusFilters, createdDates });
  };

  const cleanAppliedFilters = () => {
    getDashboardClients({ name: searchName });
  };

  const handlePagination = () => {
    setPageSize(pageSize + pageSizeDefault);
  };

  const handleSearchName = () => {
    return isPhysicalPerson
      ? filterClient.map(client => client.physicalPerson?.name ?? '')
      : filterClient.map(client => client.legalPerson?.corporateName ?? '');
  };

  const searchClientName = _.debounce(() => {
    setIsFilterName(false);
    verifyStorageAndGetClients();
  }, 500);

  const resetFilters = () => {
    setSearchName('');
    setClientStatus('');
    setInitialDate('');
    setEndDate('');
    setIsFilterName(false);
  };

  useEffect(() => {
    if (!isFilterName) {
      setFilterClient([]);
    }
  }, [isFilterName]);

  return (
    <>
      <TableListFilters
        values={handleSearchName()}
        onClickFilterDate={verifyStorageAndGetClients}
        setEndDate={setEndDate}
        setInitialDate={setInitialDate}
        searchName={searchName}
        setSearchName={setSearchName}
        setIsFilterName={setIsFilterName}
        onClick={searchClientName}
        setFilterStatus={setClientStatus}
        initialDate={initialDate}
        filterStatus={clientStatus}
        resetFilters={resetFilters}
        statusOptions={registrationStatus}
      />

      <TableList
        tableValues={setTableValues()}
        page={page}
        totalPages={totalPages}
        setNewPage={setNewPage}
        setSortColumn={setSortColumn}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        containsNextPage={containsNextPage}
        handlePagination={handlePagination}
        loading={loading}
        setViewMore={setViewMore}
        viewMore={viewMore}
      />
    </>
  );
};
