import styled from 'styled-components/macro';

interface Props {
  isSelected?: boolean;
  isSignersTab?: boolean;
}

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100%;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;

  > span {
    display: inline-block;
    font-weight: 700;
    margin-left: 16px;
  }

  > button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Container = styled.div<Props>`
  background-color: ${({ theme }) => theme.color.white};
  padding: 0px 0px 24px 0px;
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  border-radius: 4px;
  width: 1004px;
  height: ${({ isSignersTab }) => (isSignersTab ? 'auto' : '384px')};
  margin: 0 auto;
`;

export const DocIconBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.brightnessPrimary};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 6px;
`;

export const FileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  & > div.textsBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const FileTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;

  > span.fileName {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.black0};

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 700px;

    @media only screen and (max-width: 1400px) {
      max-width: 500px;
    }

    @media only screen and (max-width: 1290px) {
      max-width: 300px;
    }

    @media only screen and (max-width: 1000px) {
      max-width: 150px;
    }
  }

  > span.date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray19};
  }
`;

interface TabBoxProps {
  isMobile?: boolean;
}
export const TabBox = styled.div<TabBoxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Tab = styled.button<Props>`
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.color.white : 'transparent')};
  border: none;
  border-bottom: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.color.primaryColor : theme.color.gray20)};
  padding: 8px;

  flex: 1;

  > span {
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme, isSelected }) => (isSelected ? theme.color.primaryColor : theme.color.gray18)};
  }
`;

export const TabContent = styled.div``;

export const DocumentDownloadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {
    border: none;
    background-color: ${({ theme }) => theme.color.primaryColor};
    display: flex;
    width: 200px;
    height: 48px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;

    > span {
      font-weight: 700;
      font-size: 16px;
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const ContractInfo = styled.div`
  width: 1004px;
  height: 112px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 24px;

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 297px;
    height: 64px;
    padding: 9px 0px 10px 24px;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 4px;
    background: var(--Other-a, #f2f8ff);

    span {
      width: 100%;
      color: var(--Cool-gray-70, #4d5358);
      font-size: 16px;
      font-weight: 400;
    }

    span:nth-of-type(1) {
      color: var(--Cool-gray-80, #343a3f);
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

export const SectionBack = styled.div`
  width: 1004px;
  margin: 0 auto;

  section {
    span {
      color: var(--Cool-gray-60, #697077);
      font-size: 20px;
      font-weight: 500;
    }

    span:nth-of-type(3) {
      color: var(--Cool-gray-100, #121619);
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

export const BackSection = styled.div`
  width: 65px;
  heigth: 19px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 19px;
`;

export const ContractHeader = styled.div`
  margin: 0 auto;
  display: flex;
  width: 1004px;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 440px;
  height: 67px;
  align-items: flex-end;
  gap: 20px;

  button {
    border: none;
    background-color: ${({ theme }) => theme.color.primaryColor};
    display: flex;
    width: 200px;
    max-height: 48px;
    height: 48px;

    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;

    img {
      margin: 0;
    }
  }
`;
