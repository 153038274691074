import { TableList, TableValues } from 'features/admin/dashboard/components/table-list';
import { ContractStatus } from 'model/enums/contract-status';
import { Signatories } from 'model/signatories';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck } from 'react-icons/fi';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import { Content, Sign, SignatureContainer, Unsigned } from './styles';

interface SignaturesProps {
  signatories?: Signatories[];
  status?: ContractStatus;
}

export const Signatures = ({ signatories, status }: SignaturesProps) => {
  const { color } = useTheme();
  const [openDropDown, setOpenDropDown] = useState(false);
  const { t } = useTranslation();

  const setTableValues = useCallback(() => {
    if (signatories) {
      const tableValues: TableValues[] = [
        {
          header: t('admin.contractScreen.headers.signatory'),
          values: signatories.map(signs => signs.signatory?.name ?? ''),
          paddingLeft: '32px',
        },
        {
          header: t('admin.contractScreen.headers.email'),
          values: signatories.map(signs => signs.signatory?.email ?? ''),
        },
        {
          header: t('admin.contractScreen.headers.birth'),
          values: signatories.map(signs => StringUtils.dateFormatMask(signs.signatory?.birth ?? '')),
        },
        {
          header: t('admin.contractScreen.headers.signed'),
          values: signatories.map(signs => (
            <SignatureContainer key={signs.id}>
              <Content>
                {signs.signed ? <FiCheck color={color.new} /> : <Unsigned />}
                {signs?.signatoryType && <Sign>{t(`enum.signatoryType.${signs?.signatoryType}`)}</Sign>}
              </Content>
            </SignatureContainer>
          )),
        },
      ];

      return tableValues;
    }

    return [];
  }, [signatories]);

  return <TableList displayOrdering={false} tableValues={setTableValues()} noPagination internal />;
};
