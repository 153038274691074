import authenticationApi from 'api/authentication-api';
import registerApi from 'api/register-api';
import { AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import HttpStatus from 'model/enums/http-status';
import { IRecoveryPassword } from 'model/recovery-password';
import { User } from 'model/user';
import toastUtils from 'shared/util/toast-utils';

export interface ICheckIfClientAlreadyRegistered {
  isRegistered: boolean;
}

export const AuthenticationService = () => {
  const requestRecoveryPassword = async (email: IRecoveryPassword): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await registerApi.requestRecoveryPassword(email);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const checkIfClientAlreadyRegistered = async (documentNumber: string): Promise<ICheckIfClientAlreadyRegistered> => {
    try {
      const result: AxiosResponse<ICheckIfClientAlreadyRegistered> = await registerApi.checkIfClientAlreadyRegistered(documentNumber);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const updateAdminProfile = async (profile: User): Promise<User> => {
    try {
      const result: AxiosResponse<User> = await authenticationApi.updateAdminProfile(profile);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        toastUtils.emitSuccessToast(i18n.t('global.errorMessage.passwordChanged'));
        return Promise.reject({ status: 401 || 400 } as AxiosResponse);
      } else {
        toastUtils.emitSuccessToast(i18n.t('global.errorMessage.passwordChanged'));
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    requestRecoveryPassword,
    checkIfClientAlreadyRegistered,
    updateAdminProfile,
  };
};

export default AuthenticationService();
