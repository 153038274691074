import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components/macro';

export const CalendarContainer = styled.div`
  width: auto;
  height: auto;
`;

export const StyledCalendar = styled.div`
  .rmdp-calendar {
    width: 303px;
    height: 285px;
    padding: 4px;
  }

  .rmdp-shadow {
    box-shadow: none !important;
  }

  .rmdp-day,
  .rmdp-week-day {
    color: #000;
    cursor: pointer;
    height: 40px;
    position: relative;
    width: 40px;
  }

  .rmdp-header {
    font-size: 14px;
    margin-top: 5px;
    padding: 9px 0;
    font-size: 16px;
    font-weight: 600;

    div {
      width: 295px;
      display: flex;
    }
  }

  .rmdp-range {
    background-color: #e5eefe;
    box-shadow: none;
    color: black;
  }

  .rmdp-range-hover {
    background-color: #e5eefe;
    color: black;
  }

  .rmdp-day.rmdp-today span {
    background-color: #e5eefe;
    color: #121619;
  }

  .rmdp-range-hover.start:not(.force),
  .rmdp-range.start:not(.force) {
    background: #8089ab;
    color: #fff;
  }

  .rmdp-range-hover.end:not(.force),
  .rmdp-range.end:not(.force) {
    background: #8089ab;
    color: #fff;
  }

  @media (hover: hover) {
    .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
      background-color: #8798ad;
      color: #fff;
    }
  }
`;
