import { useTheme } from 'styled-components';

const ProfileIcon = () => {
  const { color } = useTheme();

  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 13C22.6403 13 21.3111 13.4032 20.1805 14.1586C19.0499 14.9141 18.1687 15.9878 17.6483 17.2441C17.128 18.5003 16.9918 19.8826 17.2571 21.2162C17.5224 22.5499 18.1772 23.7749 19.1387 24.7364C20.1001 25.6978 21.3251 26.3526 22.6588 26.6179C23.9924 26.8832 25.3747 26.747 26.631 26.2267C27.8872 25.7063 28.9609 24.8251 29.7164 23.6945C30.4718 22.564 30.875 21.2347 30.875 19.875C30.875 18.0516 30.1507 16.303 28.8614 15.0136C27.5721 13.7243 25.8234 13 24 13ZM24 24C23.1842 24 22.3866 23.7581 21.7083 23.3048C21.0299 22.8516 20.5012 22.2073 20.189 21.4536C19.8768 20.6998 19.7951 19.8704 19.9543 19.0703C20.1134 18.2701 20.5063 17.5351 21.0832 16.9582C21.6601 16.3813 22.3951 15.9884 23.1953 15.8293C23.9954 15.6701 24.8248 15.7518 25.5786 16.064C26.3323 16.3762 26.9766 16.9049 27.4298 17.5833C27.8831 18.2616 28.125 19.0592 28.125 19.875C28.1238 20.9686 27.6888 22.0171 26.9155 22.7904C26.1421 23.5638 25.0936 23.9988 24 24Z"
        fill={color.primaryColor ? color.primaryColor : '#7189E1'}
      />
      <path
        d="M24 4.75C20.1927 4.75 16.4709 5.87899 13.3053 7.99421C10.1396 10.1094 7.67231 13.1159 6.21533 16.6333C4.75834 20.1508 4.37713 24.0214 5.11989 27.7555C5.86266 31.4896 7.69604 34.9196 10.3882 37.6118C13.0804 40.304 16.5104 42.1373 20.2445 42.8801C23.9787 43.6229 27.8492 43.2417 31.3667 41.7847C34.8841 40.3277 37.8906 37.8604 40.0058 34.6947C42.121 31.5291 43.25 27.8073 43.25 24C43.2443 18.8963 41.2143 14.0034 37.6055 10.3945C33.9966 6.78572 29.1037 4.75575 24 4.75ZM15.75 38.2677V36.375C15.7512 35.2814 16.1862 34.2328 16.9595 33.4595C17.7328 32.6862 18.7814 32.2512 19.875 32.25H28.125C29.2187 32.2512 30.2672 32.6862 31.0405 33.4595C31.8138 34.2328 32.2488 35.2814 32.25 36.375V38.2677C29.7464 39.7296 26.8992 40.5 24 40.5C21.1008 40.5 18.2537 39.7296 15.75 38.2677ZM34.9897 36.273C34.9624 34.4693 34.2275 32.7486 32.9434 31.4817C31.6593 30.2148 29.9289 29.5031 28.125 29.5H19.875C18.0711 29.5031 16.3407 30.2148 15.0566 31.4817C13.7726 32.7486 13.0377 34.4693 13.0103 36.273C10.5168 34.0465 8.75845 31.1152 7.96797 27.8671C7.17749 24.6191 7.39221 21.2076 8.5837 18.0843C9.77519 14.961 11.8872 12.2733 14.6402 10.377C17.3932 8.48074 20.6572 7.46539 24 7.46539C27.3429 7.46539 30.6069 8.48074 33.3598 10.377C36.1128 12.2733 38.2248 14.961 39.4163 18.0843C40.6078 21.2076 40.8225 24.6191 40.0321 27.8671C39.2416 31.1152 37.4832 34.0465 34.9897 36.273Z"
        fill={color.primaryColor ? color.primaryColor : '#7189E1'}
      />
    </svg>
  );
};

export default ProfileIcon;
