import CustomButton from 'components/custom-button';
import { useCustomSideMenu } from 'components/custom-side-menu/hooks';
import { Screens } from 'model/enums/admin-dashboard';
import { Authority } from 'model/enums/authority';
import { RoutePath } from 'model/enums/route-path';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import whitePlus from '../../../../../images/white-plus.svg';
import AdminDropdown from '../admin-dropdown';
import { ButtonContainer, Content, HeaderContainer, IconContainer, Title, TitleContainer } from './styles';

interface DashboardHeaderProps {
  currentScreenPath: string;
  title: string;
  buttonDescription?: string;
  isHeaderClicked?: boolean;
  setIsHeaderClicked?: (value: boolean) => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  isHeaderClicked,
  setIsHeaderClicked,
  currentScreenPath,
  title,
  buttonDescription,
}) => {
  const account = useSelector((state: IRootState) => state.authentication.account);
  const { t } = useTranslation();
  const history = useHistory();
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const { menuOpen, returnDropdonw } = useCustomSideMenu();

  const allowedFunctions = [
    Authority.ROLE_ADMIN,
    Authority.ROLE_INTERNAL_ADMIN,
    Authority.ROLE_MANAGER,
    Authority.ROLE_FINANCIAL,
    Authority.ROLE_ANALYST,
  ];

  const isShowDashboardButton = () => {
    if (hasAuthorities(account?.authorities, allowedFunctions)) {
      setIsShowButton(true);
    }
  };

  const isShowBranchButton = () => {
    if (hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN])) {
      setIsShowButton(true);
    }
  };

  const handleScreenAction = () => {
    switch (currentScreenPath) {
      case Screens.DASHBOARD:
        return history.push(RoutePath.ADMIN_REGISTER_NEW_CUSTOMER);

      case Screens.MY_TEAM:
        setIsHeaderClicked ? setIsHeaderClicked(!isHeaderClicked) : '';
        return;

      case Screens.BRANCH:
        return history.push(RoutePath.CREATE_BRANCH);
    }
  };

  useEffect(() => {
    isShowDashboardButton();
    isShowBranchButton();
    handleScreenAction();
  }, []);

  return (
    <HeaderContainer>
      <Content>
        <TitleContainer>
          <Title> {t(title)} </Title>
        </TitleContainer>

        {isShowButton && (
          <ButtonContainer>
            {buttonDescription && (
              <CustomButton iconSrc={whitePlus} width="240px" height={48} onClick={handleScreenAction}>
                {t(buttonDescription)}
              </CustomButton>
            )}
          </ButtonContainer>
        )}
        <IconContainer>
          <AdminDropdown position="bottom right" />
        </IconContainer>
      </Content>
    </HeaderContainer>
  );
};

export default DashboardHeader;
