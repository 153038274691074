import authenticationApi from 'api/authentication-api';
import EnterCustomButton from 'components/enter-custom-button';
import ReactHookFormProvider, { FieldsError } from 'components/ReactHookFormProvider';
import InputText from 'components/styled-input';
import { NO_LANDING_PAGE } from 'config/constants';
import { Authority } from 'model/enums/authority';
import { ILogin } from 'model/login';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  ImageContainer,
  LogoContainer,
  NewPasswordContainer,
  NewPasswordInputContainer,
  SideImageContainer,
  ThinBackground,
  ThinButtonContainer,
  ThinContainer,
  ThinHeader,
  ThinRequirementItem,
  ThinRequirementsContainer,
  ThinRequirementTitle,
  ThinTitle,
} from 'styled-components/thin-styles';
import * as yup from 'yup';
import logo from '../../../../src/images/login/logo.svg';
import sideImage from '../../../../src/images/login/side-image.png';

const CreatePassword: React.FC = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const { id } = useParams<{ id?: string }>();

  const upperCaseRegex = /^(?=.*[A-Z])/;
  const lowerCaseRegex = /^(?=.*[a-z])/;
  const atLeastOneNumberRegex = /^(?=.*[0-9])/;
  const specialCharacterRegex = /^(?=.*[!@#\$%\^&\*])/;
  const characterSequenceRegex = /^(?!.*(.)\1{2})(?!.*(?:012|123|234|345|456|567|678|789|890))(?!.*(?:Giro|giro)).+$/;

  const redirectTo = NO_LANDING_PAGE ? '/login/authenticate' : '/admin/login';

  const schema = yup.object({
    password: yup
      .string()
      .required(t('global.errorMessage.required'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, t('global.errorMessage.requirementsPassword')),
    confirmPassword: yup
      .string()
      .required(t('global.errorMessage.required'))
      .oneOf([yup.ref('password'), null], t('global.errorMessage.matchPasswords')),
  });

  const fieldsError: FieldsError[] = [
    { name: 'password', errorMessage: t('global.errorMessage.login') },
    { name: 'confirmPassword', errorMessage: t('global.errorMessage.login') },
  ];

  const onHandleSubmit = (data: ILogin) => {
    setIsLoading(true);

    if (id) {
      authenticationApi
        .finishPasswordReset(id, data.password)
        .then(res => {
          if (
            res.data.authorities.includes(Authority.ROLE_ADMIN) ||
            res.data.authorities.includes(Authority.ROLE_INTERNAL_ADMIN) ||
            res.data.authorities.includes(Authority.ROLE_MANAGER) ||
            res.data.authorities.includes(Authority.ROLE_ANALYST) ||
            res.data.authorities.includes(Authority.ROLE_FINANCIAL) ||
            res.data.authorities.includes(Authority.ROLE_ATTENDANT) ||
            res.data.authorities.includes(Authority.ROLE_INTERNAL)
          ) {
            history.push('/admin/login');
          } else {
            history.push('/login');
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  };

  const passwordIsValid = () => {
    const valid =
      password.length >= 8 &&
      upperCaseRegex.test(password) &&
      lowerCaseRegex.test(password) &&
      specialCharacterRegex.test(password) &&
      atLeastOneNumberRegex.test(password) &&
      characterSequenceRegex.test(password);
    setPasswordValid(!valid);
  };

  useEffect(() => {
    passwordIsValid();
  }, [password]);

  return (
    <ThinBackground>
      <NewPasswordContainer>
        <LogoContainer>
          <ImageContainer src={logo} />
        </LogoContainer>
        <ThinHeader onClick={() => history.goBack()}></ThinHeader>
        <ThinContainer>
          <ThinTitle>{t('login.recovery.createPassword.title')}</ThinTitle>
        </ThinContainer>
        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit} fieldsWithError={fieldsError}>
          <NewPasswordInputContainer>
            <InputText
              onChange={e => setPassword(e.target.value)}
              width={300}
              inputType="password"
              name="password"
              label={t('login.label.password')}
              placeholder={t('login.placeholder.newPassword')}
            />
            <br />
            <InputText
              width={300}
              inputType="password"
              name="confirmPassword"
              label={t('login.label.confirmPassword')}
              placeholder={t('login.placeholder.newPassword')}
            />
          </NewPasswordInputContainer>
          <ThinRequirementsContainer>
            <ThinRequirementTitle>{t('login.recovery.createPassword.requirements.title')}</ThinRequirementTitle>
            <ThinRequirementItem isValid={password.length >= 8 ? true : false}>
              {t('login.recovery.createPassword.requirements.stepOne')}
            </ThinRequirementItem>
            <ThinRequirementItem isValid={upperCaseRegex.test(password)}>
              {t('login.recovery.createPassword.requirements.stepTwo')}
            </ThinRequirementItem>
            <ThinRequirementItem isValid={lowerCaseRegex.test(password)}>
              {t('login.recovery.createPassword.requirements.stepThree')}
            </ThinRequirementItem>
            <ThinRequirementItem isValid={specialCharacterRegex.test(password)}>
              {t('login.recovery.createPassword.requirements.stepFour')}
            </ThinRequirementItem>
            <ThinRequirementItem isValid={atLeastOneNumberRegex.test(password)}>
              {t('login.recovery.createPassword.requirements.stepFive')}
            </ThinRequirementItem>
            <ThinRequirementItem isValid={characterSequenceRegex.test(password)}>
              {t('login.recovery.createPassword.requirements.stepSix')}
            </ThinRequirementItem>
          </ThinRequirementsContainer>
          <ThinButtonContainer>
            <EnterCustomButton
              isInvertColor={true}
              type={'submit'}
              width={200}
              text={t('global.button.cancel')}
              disabled={false}
              isLoading={isLoading}
              onClick={() => history.push(redirectTo)}
            />
            <EnterCustomButton
              type={'submit'}
              width={200}
              text={t('global.button.confirm')}
              disabled={passwordValid}
              isLoading={isLoading}
            />
          </ThinButtonContainer>
        </ReactHookFormProvider>
      </NewPasswordContainer>
      <SideImageContainer>
        <ImageContainer src={sideImage} />
      </SideImageContainer>
    </ThinBackground>
  );
};

export default CreatePassword;
