import { CalendarInput } from 'components/calendar-input';
import CustomButton from 'components/custom-button';
import i18n from 'config/i18n';
import { ButtonContainer } from 'features/client/selfie/mobile/selfie-instructions/styles';
import ReportFilterType from 'model/filter-parameters';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import analysisReportService from 'services/analysis-report-service';
import toastUtils from 'shared/util/toast-utils';
import { ContentCalendarInputsContainer, ContentFilter, MainContent } from './styles';

const ReportCcb = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { t } = useTranslation();

  const handleSearch = () => {
    const filters: ReportFilterType = {};

    if (startDate && endDate) {
      filters['startDate'] = startDate.toISOString().slice(0, 19);
      filters['endDate'] = endDate.toISOString().slice(0, 19);

      analysisReportService.generateReportCcb(filters).then(response => {
        const file = new Blob([response.data], { type: 'text/csv' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(file);
        a.download = `relatorio_ccbs_${startDate.toISOString().slice(0, 10)}_${endDate.toISOString().slice(0, 10)}.csv`;
        document.body.appendChild(a);
        a.click();
      });
    } else {
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.reportCcb'));
    }
  };

  return (
    <>
      <MainContent>
        <ContentFilter>
          <ContentCalendarInputsContainer>
            <CalendarInput
              placeholder={t(`enum.filterAllStatus.START_DATE`)}
              onChangeValue={date => {
                if (date instanceof Date) {
                  setStartDate(date);
                } else {
                  setStartDate(null);
                }
              }}
            />
            <CalendarInput
              placeholder={t(`enum.filterAllStatus.END_DATE`)}
              onChangeValue={date => {
                if (date instanceof Date) {
                  setEndDate(date);
                } else {
                  setEndDate(null);
                }
              }}
            />
          </ContentCalendarInputsContainer>

          <ButtonContainer>
            <CustomButton style={{ justifyContent: 'center' }} height={38} width="160px" onClick={() => handleSearch()}>
              {t(`global.button.searchReport`)}
            </CustomButton>
          </ButtonContainer>
        </ContentFilter>
      </MainContent>
    </>
  );
};

export default ReportCcb;
