import { StyledButton } from 'components/custom-button/styles';
import { NO_LANDING_PAGE } from 'config/constants';
import AccessDeniedIcon from 'images/accessDenied.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  AccessDeniedContainer,
  ThinBackground,
  ThinButtonContainer,
  ThinContainer,
  ThinSubTitle,
  ThinTitle,
} from 'styled-components/thin-styles';

interface IAccessDeniedProps {
  // TODO
}

const redirectTo = NO_LANDING_PAGE ? '/login' : '/admin/login';

const AccessDenied = (props: IAccessDeniedProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <ThinBackground>
      <AccessDeniedContainer>
        <ThinContainer>
          <ThinTitle tittleFontSize="28">{t('accessDenied.title')}</ThinTitle>
        </ThinContainer>
        <img src={AccessDeniedIcon} />
        <ThinContainer>
          <ThinSubTitle subTitleFontSize="16px">{t('accessDenied.subtitle')}</ThinSubTitle>
        </ThinContainer>

        <ThinButtonContainer>
          <StyledButton
            onClick={() => {
              history.push(redirectTo);
            }}
            justifyContent="center"
          >
            {t('global.button.backStep')}
          </StyledButton>
        </ThinButtonContainer>
      </AccessDeniedContainer>
    </ThinBackground>
  );
};

export default AccessDenied;
