import CustomButton from 'components/custom-button';
import EmailSvg from 'images/email.svg';
import { truncate } from 'lodash';
import { Authority } from 'model/enums/authority';
import { ContractStatus } from 'model/enums/contract-status';
import { Contract } from 'model/financing';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiDownload } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import { ContractPdf } from '../components/contractPdf';
import { Signatures } from '../components/signatures';
import { SignQRCode } from '../components/signqrcode';
import {
  BackSection,
  ButtonContainer,
  Container,
  ContractHeader,
  ContractInfo,
  DocumentDownloadSection,
  PageContainer,
  SectionBack,
  Tab,
  TabBox,
  TabContent,
} from '../styles';

interface DesktopProps {
  contract: Contract | null;
  date: {
    day: string;
    month: string;
    year: string;
  };
  tabSelected: number;
  setTabSelected: (value: number) => void;
  downloadPdf: (value: string) => void;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
  resendEmail: () => Promise<void>;
  signatureLink: string | null;
  pageIdentification: string;
}

export const Desktop = ({
  pageIdentification,
  contract,
  date,
  tabSelected,
  setTabSelected,
  downloadPdf,
  setIsLoading,
  isLoading,
  resendEmail,
  signatureLink,
}: DesktopProps) => {
  const { color } = useTheme();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [isSignersTab, setIsSignersTab] = useState(false);

  useEffect(() => {}, [isSignersTab]);

  const getTabContent = () => {
    if (signatureLink != null) {
      switch (tabSelected) {
        case 1:
          return <SignQRCode status={contract?.status} url={signatureLink} />;
        case 2:
          return <Signatures signatories={contract?.contractSign?.contractSignatories} status={contract?.status} />;
        case 3:
          return <ContractPdf url={contract?.contractSign?.attachment?.presignedUrl ?? contract?.attachment?.presignedUrl ?? ''} />;
        default:
          return <></>;
      }
    } else {
      switch (tabSelected) {
        case 1:
          return <Signatures signatories={contract?.contractSign?.contractSignatories} status={contract?.status} />;
        case 2:
          return <ContractPdf url={contract?.contractSign?.attachment?.presignedUrl ?? contract?.attachment?.presignedUrl ?? ''} />;
        default:
          return <></>;
      }
    }
  };

  return (
    <PageContainer>
      <ContractHeader>
        <SectionBack>
          <BackSection
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
            <span>{t('global.button.backStep')}</span>
          </BackSection>
          <section>
            <span>{t('updateBankDataScreen.step')}</span>
            <span>{` / ${pageIdentification} / `}</span>
            <span>{t('admin.contractScreen.title')}</span>
          </section>
        </SectionBack>

        <ButtonContainer>
          <DocumentDownloadSection>
            {contract?.contractSign?.attachment?.presignedUrl ? (
              <button onClick={() => downloadPdf(contract?.contractSign?.attachment?.presignedUrl ?? '')}>
                <FiDownload size={20} color={color.white} />
                <span>{t('admin.contractScreen.downloadAssignedButton')}</span>
              </button>
            ) : (
              <button onClick={() => downloadPdf(contract?.attachment?.presignedUrl ?? '')}>
                <FiDownload size={20} color={color.white} />
                <span>{t('admin.contractScreen.downloadButton')}</span>
              </button>
            )}
          </DocumentDownloadSection>
          {contract?.status !== ContractStatus.FINISHED &&
            hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_MANAGER]) && (
              <CustomButton
                height={48}
                loading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  resendEmail();
                }}
              >
                <img src={EmailSvg} alt="Email" />
                {t('admin.contractScreen.buttonText')}
              </CustomButton>
            )}
        </ButtonContainer>
      </ContractHeader>

      <ContractInfo>
        <section>
          <div>
            <span>{t('admin.contractScreen.title')}</span>
            <span>{truncate(contract?.attachment.fileName, { length: 25 })}</span>
          </div>

          <div>
            <span>{t(`admin.contractScreen.status`)}</span>
            <span>{t(`enum.filterAllStatus.${contract?.status}`)}</span>
          </div>

          <div>
            <span>{t(`admin.contractScreen.Added`)}</span>
            <span>
              {date.day && date.month && date.year
                ? t('admin.contractScreen.date', {
                    day: date.day,
                    month: date.month,
                    year: date.year,
                  })
                : '...'}
            </span>
          </div>
        </section>
      </ContractInfo>
      <Container isSignersTab>
        <TabBox>
          {signatureLink != null && (
            <Tab isSelected={tabSelected === 1} onClick={() => setTabSelected(1)}>
              <span>{t('admin.contractScreen.tabs.sign')}</span>
            </Tab>
          )}
          <Tab
            isSelected={signatureLink != null ? tabSelected === 2 : tabSelected === 1}
            onClick={() => {
              setIsSignersTab(true);
              signatureLink != null ? setTabSelected(2) : setTabSelected(1);
            }}
          >
            <span>{t('admin.contractScreen.tabs.signatories')}</span>
          </Tab>
          <Tab
            isSelected={signatureLink != null ? tabSelected === 3 : tabSelected === 2}
            onClick={() => (signatureLink != null ? setTabSelected(3) : setTabSelected(2))}
          >
            <span>{t('admin.contractScreen.tabs.document')}</span>
          </Tab>
        </TabBox>
        <TabContent>{getTabContent()}</TabContent>
      </Container>
    </PageContainer>
  );
};
