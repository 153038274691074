import styled from 'styled-components';

interface Props {
  isContainsSaveButton?: boolean;
  marginTop?: number;
}

export const BackGround = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  margin: 0 auto;
`;

export const CreditInformationContainer = styled.div`
  margin: 0 auto;
  margin-top: 24px;
  display: flex;
  width: 1004px;
  max-height: 476px;
  min-height: 287px;
  padding: 24px 25px 24px 24px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export const TextAreaContainer = styled.div`
  margin-top: 20px;

  label {
    border: 1px solid #999999;
    border-radius: 8px;
    height: 88px;

    span {
      margin-left: 8px;
    }
  }

  textarea {
    height: 44px;
  }

  div {
    height: 44px;
    border: none;
  }

  section {
    width: 28px;
    height: 28px;
    background-color: #e5eefe;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
  }
`;

export const CreditInformationSection = styled.div`
  width: 220px;
  height: 215px;
`;

export const HighlightInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 64px;
  padding: 9px 16px 10px 24px;
  align-items: center;
  border-radius: 4px;
  background: var(--Other-a, #f2f8ff);
`;

export const CreditInformationTitle = styled.span`
  color: var(--Cool-gray-50, #878d96);
  font-size: 16px;
  font-weight: 600;
`;

export const InputEditSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  span {
    color: var(--Cool-gray-50, #878d96);
    font-size: 16px;
    font-weight: 600;
  }

  label {
    width: 220px;
    height: 62px;
    border-radius: 8px;
    border: 1px solid var(--Cool-gray-30, #c1c7cd);
    padding: 8px 28px 8px 8px;
  }

  div {
    min-width: 220px;
    height: 24px;
    border: none;
    margin: 0;
    padding: 0;

    input {
      width: 168px;
      color: var(--Cool-gray-70, #4d5358);
      font-size: 16px;
      font-weight: 400;
      background-color: var(--White, #ffffff);
    }

    section {
      width: 28px;
      height: 28px;
      background-color: #e5eefe;
      border-radius: 9999px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }
  }
`;

export const InputSection = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '20px')};

  span {
    color: var(--Cool-gray-50, #878d96);
    font-size: 16px;
    font-weight: 600;
  }

  div {
    min-width: 220px;
    border: none;
    margin: 0;
    padding: 0;

    input {
      width: 220px;
      color: var(--Cool-gray-70, #4d5358);
      font-size: 16px;
      font-weight: 400;
      background-color: var(--White, #ffffff);
    }
  }
`;

export const PaymentInfoContainer = styled.div`
  display: flex;
  width: 1004px;
  height: 202px;
  padding-left: 24px;
  align-items: flex-start;
  gap: 45px;

  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
`;

export const PaymentSection = styled.div`
  width: 200px;
  height: 110px;

  div {

    input {
      width: 148px;
      color: var(--Cool-gray-70, #4d5358);
      font-size: 16px;
      font-weight: 400;
    }
`;

export const Payment = styled.div`
  width: 1004px;
  height: 202px;
  margin: 0 auto;
  margin-top: 34px;
`;

export const SectionTitle = styled.div`
  width: 1004px;
  margin-bottom: 20px;

  span {
    color: var(--Cool-gray-100, #121619);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const Contract = styled.div<Props>`
  width: 1004px;
  margin: 0 auto;
  margin-top: ${({ isContainsSaveButton }) => (isContainsSaveButton ? '32px' : '85px')};
`;

export const ContractInfoContainer = styled.div`
  width: 1004px;
  height: 93px;
  display: flex;
  align-items: center;
  gap: 24px;
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
  padding-left: 24px;
`;

export const ContractSection = styled.div`
  width: 221px;
  height: 45px;
  display: flex;
  flex-direction: column;
`;

export const ContractButtonContainer = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;

  button {
    border: none;
    width: 200px;
  }
`;

export const SaveEditButtons = styled.div`
  width: 1004px;
  height: 104px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin: 0 auto;
  padding-bottom: 24px;
  padding-left: 24px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const Labelinput = styled.div`
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100px;

  section {
    border: 1px solid var(--Cool-gray-30, #c1c7cd);
    width: 220px;
    height: 62px;
    border-radius: 8px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #656666;
    margin-left: 8px;
  }

  span + span {
    margin-left: 0.125rem;
    color: #f0654a;
  }

  .bNyJYp input {
    margin: 0;
    width: 200px;
    margin-bottom: 0.625rem;
  }

  .jIUOuJ {
    border: none;
  }

  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
    border: none;
  }
`;

export const SignatoryContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Sign = styled.span`
  display: inline-block;
  margin-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const Unsigned = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.color.gray20};
  border-radius: 50%;
`;

export const SignatoriesBox = styled.div`
  grid-area: signatories;
  display: flex;
  flex-direction: column;
`;

export const FileNameBox = styled.div`
  grid-area: filename;
  display: flex;
  align-items: center;

  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray19};
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
  }
`;

export const ContractData = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export const ContractContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContractHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;
