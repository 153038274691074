import styled from 'styled-components/macro';

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
`;

export const WelcomeImage = styled.div`
  width: 316px;
  height: 316px;
  flex-shrink: 0;
  border-radius: 316px;
  border: 64px solid #4262d7;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  margin-right: 17px;
  margin-top: 10px;
`;

export const InternalImage = styled.div`
  width: 128px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 128px;
  border: 64px solid #4262d7;
  opacity: 0.5;
  margin-top: 30px;
`;

export const WelcomeTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WelcomeCardContainer = styled.div`
  width: 1004px;
  height: 116px;
  margin: 0 auto;
  flex-shrink: 0;
  background: ${({ theme }) => theme.color.primaryColor};
  border-radius: 16px;
  margin-top: 64px;
  overflow: hidden;
  color: var(--White, #fff);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h3 {
    margin-left: 40px;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-left: 40px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  svg {
    margin-top: 10px;
    margin-right: 17px;
  }
`;

export const QickAccessTitle = styled.div`
  margin-top: 32px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
