import { ORGANIZATION_NAME, ORGANIZATION_TYPE } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import _, { truncate } from 'lodash';
import { FinancingDashboard } from 'model/dashboard';
import { CreatedDates } from 'model/date';
import { FinancingStatus } from 'model/enums/financing-status';
import { OrganizationName } from 'model/enums/organization-name';
import { OrganizationType } from 'model/enums/organization-type';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { getStorageFilter } from 'shared/util/filter-utils';
import StringUtils from 'shared/util/string-utils';
import { Status } from '../../components/status';
import { TableId, TableList, TableValues } from '../../components/table-list';
import TableListFilters from '../../components/table-list-filters';

interface StatusTypeFilter {
  label: string;
  value: string;
}

export const CreditAnalysisList = () => {
  const pageSizeDefault = 8;
  const [financings, setFinancings] = useState<FinancingDashboard[]>([]);
  const [filterClientByFinancing, setFilterClientByFinancing] = useState<FinancingDashboard[]>([]);
  const [searchName, setSearchName] = useState('');
  const [isFilterName, setIsFilterName] = useState(false);
  const [sortColumn, setSortColumn] = useState('');
  const [pageSize, setPageSize] = useState(pageSizeDefault);
  const [containsNextPage, setContainsNextPage] = useState(false);
  const [financingStatus, setFinancingStatus] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [orderBy, setOrderBy] = useState<boolean>(false);
  const { t } = useTranslation();
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;
  const isHcred = ORGANIZATION_NAME === OrganizationName.HCRED;
  const [initialDate, setInitialDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const { filters } = useContext(AdminFiltersContext);

  const filterFinancingStatusValues: StatusTypeFilter[] = [
    { label: t(`enum.financingStatus.${FinancingStatus.NEW}`), value: FinancingStatus.NEW },
    { label: t(`enum.financingStatus.${FinancingStatus.IN_PROGRESS}`), value: FinancingStatus.IN_PROGRESS },
    { label: t(`enum.financingStatus.${FinancingStatus.VALIDATION}`), value: FinancingStatus.VALIDATION },
    { label: t(`enum.financingStatus.${FinancingStatus.PENDING_PAYMENT}`), value: FinancingStatus.PENDING_PAYMENT },
    { label: t(`enum.financingStatus.${FinancingStatus.PENDING_SIGN}`), value: FinancingStatus.PENDING_SIGN },
    { label: t(`enum.financingStatus.${FinancingStatus.ADJUST}`), value: FinancingStatus.ADJUST },
    { label: t(`enum.financingStatus.${FinancingStatus.ADJUSTED}`), value: FinancingStatus.ADJUSTED },
    { label: t(`enum.financingStatus.${FinancingStatus.APPROVED}`), value: FinancingStatus.APPROVED },
    { label: t(`enum.financingStatus.${FinancingStatus.FINISHED}`), value: FinancingStatus.FINISHED },
    { label: t(`enum.financingStatus.${FinancingStatus.REJECTED}`), value: FinancingStatus.REJECTED },
    { label: t(`enum.financingStatus.${FinancingStatus.CANCELLED}`), value: FinancingStatus.CANCELLED },
  ];

  const resetFilters = () => {
    setSearchName('');
    setFinancingStatus('');
    setInitialDate('');
    setEndDate('');
    setIsFilterName(false);
  };

  useEffect(() => {
    verifyStorageAndGetFinancings();
  }, [financingStatus, pageSize, searchName, page, orderBy, filters, sortColumn, initialDate, viewMore]);

  useEffect(() => {
    if (!isFilterName) {
      setFilterClientByFinancing([]);
    }
  }, [isFilterName]);

  const handlePagination = () => {
    setPageSize(pageSize + pageSizeDefault);
  };

  const verifyStorageAndGetFinancings = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }

    getDashboardFinancings({
      name: searchName,
      page,
      financingStatus: financingStatus,
      createdDates,
    });
  };

  const getDashboardFinancings = async (props?: {
    name?: string;
    size?: number;
    page?: number;
    financingStatus;
    createdDates?: CreatedDates;
    identification?: string;
    subsidiaryName?: string;
  }) => {
    if (!viewMore) setLoading(true);
    const res = await DashboardService().getFinancingsList(
      props?.name,
      pageSize,
      props?.page,
      financingStatus,
      props?.identification,
      orderBy,
      props?.subsidiaryName,
      sortColumn,
      initialDate,
      endDate
    );

    if (res && !viewMore) setLoading(false);
    isFilterName ? setFilterClientByFinancing(res.content) : setFinancings(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
    setContainsNextPage(res.size < res.totalElements);
  };

  const handleSearchName = () => {
    return isPhysicalPerson
      ? filterClientByFinancing.map(financing => financing.client.physicalPerson?.name ?? '')
      : filterClientByFinancing.map(financing => financing.client.legalPerson?.corporateName ?? '');
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: isPhysicalPerson ? t('admin.dashboard.tableHeaders.name') : t('admin.dashboard.tableHeaders.corporateName'),
        values: isPhysicalPerson
          ? financings.map(financing => truncate(financing.client.physicalPerson?.name, { length: 15 }) ?? '')
          : financings.map(financing => truncate(financing.client.legalPerson?.corporateName, { length: 15 }) ?? ''),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
        paddingLeft: '10px',
        sortColumn: 'client.physicalPerson.name',
      },
      {
        header: t('admin.dashboard.tableHeaders.identification'),
        values: financings.map(financing => financing.identification ?? ''),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
        sortColumn: 'identification',
      },
      {
        header: t('admin.dashboard.tableHeaders.ccb'),
        values: financings.map(financing => financing?.ccb ?? '---'),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
        maxWidth: '120px',
        sortColumn: 'ccb',
      },
      {
        header: t('admin.dashboard.tableHeaders.choosedValue'),
        values: financings.map(financing => StringUtils.moneyMaskPtbr(financing.choosedValue)),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
        maxWidth: '150px',
        sortColumn: 'choosedValue',
      },
      {
        header: t('admin.dashboard.tableHeaders.createdDate'),
        values: financings.map(financing => formatDateToLocalFormat(financing.createdDate)),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
        maxWidth: '125px',
        sortColumn: 'createdDate',
      },
      {
        header: t('admin.dashboard.tableHeaders.lastModifiedDate'),
        values: financings.map(financing => formatDateToLocalFormat(financing.lastModifiedDate)),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
        maxWidth: '130px',
        sortColumn: 'lastModifiedDate',
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: financings.map(financing => (
          <Status
            key={financing.id}
            text={t(
              `enum.financingStatus.${
                isHcred ? (financing.financingHcredStatus ? financing.financingHcredStatus : 'UNKNOWN') : financing.financingStatus
              }`
            )}
            color={financing.financingStatus}
          />
        )),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
        sortColumn: 'financingStatus',
        showMore: 'Ver mais',
      },
    ];

    return tableValues;
  };

  const searchClientName = _.debounce(() => {
    setIsFilterName(false);
    verifyStorageAndGetFinancings();
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  return (
    <>
      <TableListFilters
        values={handleSearchName()}
        onClickFilterDate={verifyStorageAndGetFinancings}
        setEndDate={setEndDate}
        setInitialDate={setInitialDate}
        searchName={searchName}
        setSearchName={setSearchName}
        setIsFilterName={setIsFilterName}
        onClick={searchClientName}
        setFilterStatus={setFinancingStatus}
        initialDate={initialDate}
        filterStatus={financingStatus}
        resetFilters={resetFilters}
        statusOptions={filterFinancingStatusValues}
      />

      <TableList
        tableValues={setTableValues()}
        page={page}
        totalPages={totalPages}
        setNewPage={setNewPage}
        setSortColumn={setSortColumn}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        containsNextPage={containsNextPage}
        handlePagination={handlePagination}
        loading={loading}
        setViewMore={setViewMore}
        viewMore={viewMore}
      />
    </>
  );
};
