import { Status } from 'features/admin/dashboard/components/status';
import { Contract } from 'model/financing';
import { useTranslation } from 'react-i18next';
import { FiCheck, FiChevronRight, FiFileText } from 'react-icons/fi';
import { GrAttachment } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  AccessButton,
  CardContainer,
  Container,
  EmptyContainer,
  FileNameBox,
  HeadersContainer,
  Separator,
  Sign,
  SignatoriesBox,
  SignatoryContent,
  StatusBox,
  Title,
  Unsigned,
} from './styles';

interface Props {
  contracts: Contract[];
}

export const TabContracts = ({ contracts }: Props) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container>
      {contracts.length === 0 ? (
        <EmptyContainer>
          <FiFileText size={64} color={color.gray23} />
          <span>{t('admin.customerAnalysis.tabContracts.emptyList')}</span>
        </EmptyContainer>
      ) : (
        <>
          <HeadersContainer>
            <Title className="contract">{t('admin.customerAnalysis.tabContracts.headers.contract')}</Title>
            <Title className="signs">{t('admin.customerAnalysis.tabContracts.headers.signatures')}</Title>
            <Title className="status">{t('admin.customerAnalysis.tabContracts.headers.status')}</Title>
          </HeadersContainer>

          {contracts?.map(contract => (
            <CardContainer key={contract.id}>
              <Separator />
              <FileNameBox>
                <GrAttachment color={color.gray19} size={15} />
                <span>{contract.attachment.fileName}</span>
              </FileNameBox>
              <SignatoriesBox>
                {contract?.contractSign?.contractSignatories?.map(signatory => (
                  <SignatoryContent key={signatory.id}>
                    {signatory.signed ? <FiCheck color={color.new} /> : <Unsigned />}
                    <Sign>{signatory?.signatory?.email}</Sign>
                  </SignatoryContent>
                ))}
              </SignatoriesBox>
              <StatusBox>
                <Status text={t(`enum.filterAllStatus.${contract.status}`)} color={contract.status} />
                <AccessButton
                  onClick={() => history.push(`/admin/contratos/analise/${contract.id}`, { prevPath: history.location.pathname })}
                >
                  <span>{t('global.button.access')}</span>
                  <FiChevronRight color={color.primaryColor} size={16} />
                </AccessButton>
              </StatusBox>
            </CardContainer>
          ))}
        </>
      )}
    </Container>
  );
};
