import { AuthBiometric, TwoFactorAuth } from 'model/auth-biometric';
import { FinancingCreditCards } from 'model/credit-card';
import { GeneralStatus } from 'model/enums/general-status';
import SystemStepCategory from 'model/enums/system-step-category';
import { TwoFactorStatus } from 'model/enums/two-factors-status';
import TypeVerifyIdentity from 'model/enums/type-verify-identity';
import { Contract, Financing, Payment, PaymentData } from 'model/financing';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clientService from 'services/client-service';
import financingService from 'services/financing-service';
import { useTheme } from 'styled-components';
import ProgressBarIcon from './progress-bar-icon';
import { MainContainer, ProgressBar, ProgressBarContainer, ProgressSection, StepDescription, StepNumber, SvgContainer } from './styles';

interface StepBarFinanceProgress {
  steps: SystemStepCategory[];
  finance?: Financing;
  verifyIdentity?: TypeVerifyIdentity;
  procedurePayment?: PaymentData;
}

interface StepStatus {
  stepName: SystemStepCategory;
  order: number;
  status: string | null;
  success?: boolean;
  current?: boolean;
  next?: boolean;
}

const actualStepStatus = [
  GeneralStatus.VALIDATION,
  GeneralStatus.ADJUST,
  GeneralStatus.ADJUSTED,
  GeneralStatus.WAITING,
  GeneralStatus.NEW,
  GeneralStatus.IN_PROGRESS,
  GeneralStatus.PENDING,
];

const successStepStatus = [
  GeneralStatus.APPROVED,
  GeneralStatus.FINISHED,
  GeneralStatus.DONE,
  GeneralStatus.IN_PROGRESS_ANALYSIS,
  GeneralStatus.PAID,
  GeneralStatus.PENDING_PAYMENT,
  GeneralStatus.PENDING_SIGN,
];

const rejectedStepStatus = [GeneralStatus.REJECTED, GeneralStatus.CANCELLED, GeneralStatus.ERROR];

export const StepBarFinanceProgress = ({ steps, finance, verifyIdentity, procedurePayment }: StepBarFinanceProgress) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const [stepStatus, setStepStatus] = useState<StepStatus[]>([]);
  const [authBio, setAuthBio] = useState<AuthBiometric | null>(null);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [payment, setPayment] = useState<Payment | null>(null);
  const [creditCard, setCreditCard] = useState<FinancingCreditCards>();
  const [loading, setLoading] = useState(false);
  const [changeIconColor, setChangeIconColor] = useState(false);

  useEffect(() => {
    if (steps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) || steps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA)) {
      getAuthBiometric();
    }

    if (steps.includes(SystemStepCategory.CONTRACT) || steps.includes(SystemStepCategory.CONTRACT_INTERNAL)) {
      getContracts();
    }

    if (steps.includes(SystemStepCategory.PAYMENT)) {
      getPayment();
    }

    if (steps.includes(SystemStepCategory.CREDIT_CARD)) {
      getCreditCard();
    }

    if (steps != null && finance != null) {
      getStepStatus();
    }
  }, [steps, finance, procedurePayment]);

  useEffect(() => {
    getStepStatus();
  }, [authBio, contracts, payment, creditCard, verifyIdentity, changeIconColor]);

  const getAuthBiometric = async () => {
    setLoading(true);
    if (finance?.id) {
      const res = await financingService.getAuthenticateAndBiometry(finance.id);

      if (res != null) {
        setAuthBio(res);
        setLoading(false);
      }
    }
  };

  const getContracts = async () => {
    setLoading(true);
    if (finance?.id) {
      const res = await financingService.getAllContractsFinancing(finance.id);

      if (res.content) {
        setContracts(res.content);
        setLoading(false);
      }
    }
  };

  const getPayment = async () => {
    setLoading(true);
    if (finance?.id) {
      const res = await financingService.getPaymentProgress(finance.id);

      if (res) {
        setLoading(false);
        setPayment(res.payment);
      }
    }
  };

  const getCreditCard = async () => {
    setLoading(true);

    if (finance?.id != null) {
      await clientService.getCreditCardFromFinancing(finance.id).then(response => {
        const activeCreditCard = response.financingCreditCards?.filter(card => card.isActive);

        if (activeCreditCard != null && activeCreditCard?.length > 0) {
          setCreditCard(activeCreditCard[0]);
        }

        setLoading(false);
      });
    }
  };

  const getStepStatus = () => {
    const newStepStatus: StepStatus[] = steps.map((step, idx) => ({
      stepName: step,
      order: idx,
      status: null,
      success: false,
      current: false,
    }));

    newStepStatus.forEach(step => {
      switch (step.stepName) {
        case SystemStepCategory.FINANCING_ANALYSIS:
        case SystemStepCategory.PROCEDURE_ANALYSIS:
          if (finance?.financingStatus) {
            let status = finance.financingStatus as unknown as GeneralStatus;

            if (status === GeneralStatus.IN_PROGRESS || successStepStatus.includes(status)) {
              status = GeneralStatus.IN_PROGRESS_ANALYSIS;
              newStepStatus[step.order].success = true;
            }
            newStepStatus[step.order].status = status;
          }
          break;

        case SystemStepCategory.SIMULATOR:
          if (finance?.isConfirmed) {
            newStepStatus[step.order].status = GeneralStatus.APPROVED;
            newStepStatus[step.order].success = true;
          } else {
            newStepStatus[step.order].status = GeneralStatus.VALIDATION;
          }
          break;

        case SystemStepCategory.BANK_ACCOUNT_DATA_MULTTIPLO:
        case SystemStepCategory.BANK_ACCOUNT_DATA:
          if (finance?.bankAccount != null) {
            newStepStatus[step.order].status = GeneralStatus.APPROVED;
            newStepStatus[step.order].success = true;
          } else {
            newStepStatus[step.order].status = GeneralStatus.VALIDATION;
          }
          break;

        case SystemStepCategory.FRAUD_ANALYSIS_2FA:
          if (verifyIdentity === TypeVerifyIdentity.NECESSARY) {
            if (authBio?.twoFactorAuth[0] != null) {
              newStepStatus[step.order].status = setCorrectAuthStatus(authBio.twoFactorAuth[0]);
            }
          } else if (verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY) {
            newStepStatus[step.order].status = GeneralStatus.APPROVED;
            newStepStatus[step.order].success = true;
          }

          break;

        case SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY:
          if (verifyIdentity === TypeVerifyIdentity.NECESSARY) {
            if (authBio?.biometrics != null) {
              newStepStatus[step.order].status = authBio.biometrics.status;
            }
          } else if (verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY) {
            newStepStatus[step.order].status = GeneralStatus.APPROVED;
            newStepStatus[step.order].success = true;
          }
          break;

        case SystemStepCategory.CONTRACT_INTERNAL:
        case SystemStepCategory.CONTRACT:
          if (contracts?.[0]?.status != null) {
            newStepStatus[step.order].status = contracts[0].status;

            let status = contracts?.[0]?.status as unknown as GeneralStatus;
            if (status === GeneralStatus.FINISHED) {
              newStepStatus[step.order].success = true;
            }
          }
          break;

        case SystemStepCategory.PAYMENT:
          if (payment?.status) {
            newStepStatus[step.order].status = payment.status;

            let status = payment?.status as unknown as GeneralStatus;
            if (status === GeneralStatus.FINISHED) {
              newStepStatus[step.order].success = true;
            }
          }
          break;

        case SystemStepCategory.CREDIT_CARD:
          if (creditCard?.creditCard?.status != null) {
            newStepStatus[step.order].status = creditCard.creditCard.status;
          }
          break;

        case SystemStepCategory.PAYMENT_MULTTIPLO_CLINIC:
          if (procedurePayment?.procedurePayment?.procedurePaymentStatus) {
            newStepStatus[step.order].status = procedurePayment.procedurePayment.procedurePaymentStatus;
          }
          break;

        case SystemStepCategory.PAYMENT_MULTTIPLO_REFUND:
          if (procedurePayment?.procedureRefund?.procedureRefundStatus) {
            newStepStatus[step.order].status = procedurePayment.procedureRefund.procedureRefundStatus;
          }
          break;

        default:
          break;
      }
    });

    setStepStatus(newStepStatus);
  };

  const setCorrectAuthStatus = (auth: TwoFactorAuth) => {
    switch (auth.status) {
      case TwoFactorStatus.REPLIED:
        return auth.verified ? GeneralStatus.APPROVED : GeneralStatus.REJECTED;

      case TwoFactorStatus.SENT:
        return auth.verified ? GeneralStatus.APPROVED : GeneralStatus.VALIDATION;

      case TwoFactorStatus.EXPIRED:
        return GeneralStatus.REJECTED;
      default:
        return null;
    }
  };

  const handleProgressBarColor = (step: string, currentIndex: number) => {
    const isCurrentStep = actualStepStatus.includes(step as GeneralStatus);
    const previousStep = stepStatus.find(s => s.order === currentIndex - 1);
    const wasPreviousStepSuccessful = previousStep?.success;

    if (currentIndex === 0 && isCurrentStep) {
      return color.barColorForCurrentStep;
    }

    if (currentIndex !== 0 && isCurrentStep && wasPreviousStepSuccessful) {
      return color.barColorForCurrentStep;
    }

    if (successStepStatus.includes(step as GeneralStatus)) {
      return color.barColorForSuccessStep;
    }

    return color.barColorForDefault;
  };

  if (loading) {
    return <></>;
  }

  return (
    <MainContainer>
      {stepStatus.map((step, idx) => (
        <ProgressSection marginLeft={idx === 0} key={idx}>
          <StepNumber>
            {`${idx + 1}.`} <StepDescription>{t(`enums.steps.${step.stepName}`)}</StepDescription>
          </StepNumber>

          <ProgressBarContainer>
            <SvgContainer backGroundColor={handleProgressBarColor(step.status ? step.status : '', idx)}>
              <ProgressBarIcon changeColor={handleProgressBarColor(step.status ? step.status : '', idx)} />
            </SvgContainer>
            <ProgressBar backGroundColor={handleProgressBarColor(step.status ? step.status : '', idx)} />
          </ProgressBarContainer>
        </ProgressSection>
      ))}
    </MainContainer>
  );
};
